import React, { useState, useMemo } from "react";
import { Grid, Typography } from "@material-ui/core";
import { TextField, PhoneInput } from "components/FormFields";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import { Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { isMailValid } from "utils/validations";
import { isValidPhoneNumber } from "react-phone-number-input";
import * as ROUTES from "utils/constants.js";
import { replace } from "lodash";

const NotAvailable = ({
  classes,
  actions,
  offerPostLogSetId,
  isLoading,
  isLoaded,
  marina,
  data
}) => {
  const [mail, setMail] = useState("");
  const [phone, setPhone] = useState("");

  const handleChangeMail = ({ value }) => {
    setMail(value);
  };

  const handleChangePhone = ({ value }) => {
    setPhone(value);
  };

  const submit = () => {
    actions.submit({
      name: "",
      mail,
      phone: replace(phone, "+", ""),
      offerPostLogSetId // TODO: Fix This. We are not receiving this value.
    });
  };

  const showDisable = useMemo(() => {
    if (
      !isMailValid(mail) ||
      !isValidPhoneNumber(phone) ||
      (!isLoading && isLoaded) ||
      isLoading
    ) {
      return true;
    }
    return false;
  }, [mail, phone, isLoading, isLoaded]);

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid container>
        <Typography component="h3" className={classes.title}>
          No space is available for the dates you requested but{" "}
          <strong>{marina.marinName}</strong> may be able to still accommodate
          you.
        </Typography>
        <Typography component="h4" className={classes.subtitle}>
          Please provide your information so we can get in touch, however note that 
          submitting this form does not guarantee a space, and we will only reach out 
          if there is availability at the time of your request. To increase your chances of 
          getting a booking for these dates and dimensions we recommend checking the website 
          frequently as cancellations sometimes occur.
        </Typography>
      </Grid>

      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={4}
      >
        <Grid item xs={12} sm={6}>
          <TextField
            id="email"
            label="Email"
            value={mail}
            validate={mail !== "" ? v => isMailValid(v) : undefined}
            submitValue={e => handleChangeMail(e)}
            required={mail !== "" ? !isMailValid(mail) : false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PhoneInput
            id="phone"
            label="Phone"
            placeHolder="Phone number"
            value={phone}
            validate={v => isValidPhoneNumber(v)}
            submitValue={e => handleChangePhone(e)}
            required={phone !== "" ? !isValidPhoneNumber(phone) : false}
          />
        </Grid>

        {/* Success */}
        {data && (
          <Grid item xs={12}>
            <div className={classes.submitted}>
              <Typography variant="h6" className={classes.success}>
                A message to the business has been sent!
              </Typography>

              <Link className={classes.linkBack} to={ROUTES.RESOURCES}>
                Click here to search again.
              </Link>
            </div>
          </Grid>
        )}

        {/* Error */}
        {!data && isLoaded && (
          <Grid item xs={12}>
            <div className={classes.submittedWithError}>
              <Typography variant="h6" className={classes.error}>
                Opss! Something went wrong!
              </Typography>

              <Link className={classes.linkBack} to={ROUTES.RESOURCES}>
                Click here to search again.
              </Link>
            </div>
          </Grid>
        )}

        <Hidden only="xs">
          <Grid item sm={8} />
        </Hidden>
        <Grid item xs={12} sm={4}>
          <Button
            variant="contained"
            color="primary"
            className={classes.submitButton}
            disabled={showDisable}
            onClick={() => submit()}
          >
            Submit
            {isLoading && (
              <CircularProgress
                style={{ right: "10%", position: "absolute" }}
                size={15}
              />
            )}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NotAvailable;
