import SummaryBox from "./summary.js";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles.js";

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = {};
  return { actions };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SummaryBox));
