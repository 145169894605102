import * as SIZE from "utils/constants.js";

export default theme => ({
  col1: {
    margin: "0 6%",
    backgroundColor: "#F5F5F5",
    minHeight: "300px",
    [theme.breakpoints.down(SIZE.SMALL)]: {
      top: "unset",
      right: "unset",
      left: "unset",
      height: "100%",
      width: "96%",
      padding: "2%",
      margin: 0,
      position: "relative",
      display: "flex",
      flex: 1
    }
  },
  description: {
    position: "relative",
    width: "40%",
    padding: "3%",
    textAlign: "left",
    [theme.breakpoints.down(SIZE.SMALL)]: {
      width: "100%",
      paddingTop: "30px"
    }
  },
  col2: {
    position: "fixed",
    height: "70vh",
    top: "15%",
    width: "30%",
    right: "10%",
    [theme.breakpoints.down(SIZE.MEDIUM)]: {
      width: "40%"
    },
    [theme.breakpoints.down(SIZE.SMALL)]: {
      top: "0",
      right: "0",
      width: "90%",
      margin: "5%",
      height: "100%",
      position: "relative"
    }
  },
  root: {
    // boxShadow: "none",
    boxShadow: `0px 0px 10px 0px #cccccc`,
    padding: "5vh 50px",
    // height: "calc(100% - 100px)",
    maxWidth: "500px",
    [theme.breakpoints.down(SIZE.SMALL)]: {
      maxWidth: "100%"
    }
  },
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridGap: `${theme.spacing(3)}px`
  },
  form: {
    height: "100%"
    // backgroundColor: "white"
    // overflowY: "auto",
    // boxShadow: `0px 0px 10px 0px #cccccc`
  },
  fontTitle: {
    textAlign: "left",
    padding: "20px 0",
    [theme.breakpoints.down(SIZE.SMALL)]: {
      paddingTop: 0
    }
  },
  fontSubTitle: {
    textAlign: "center",
    paddingTop: "40px"
  },
  button: {
    width: "100%",
    fontSize: "14px",
    padding: "15px"
  },
  promoLink: {
    fontSize: "18px",
    cursor: "pointer",
    textDecoration: "underline",
    color: theme.palette.primary.main
  },
  error: {
    textAlign: "center",
    color: theme.palette.error.main
  },
  click: {
    cursor: "pointer",
    textDecoration: "underline"
  },
  departureContainer: {
    marginLeft: "18px",
    paddingTop: 0,
    textAlign: "left",
    width: "100%"
  },
  lengthContainer: {
    marginLeft: "18px",
    paddingTop: 0,
    width: "100%",
    marginBottom: 0,
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      display: "none"
    }
  },
  lengthContainerGrid: {
    display: "none",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      display: "block",
      marginBottom: 0,
      paddingTop: 0,
      width: "100%"
    }
  },
  lengthTypo: {
    marginBottom: 0,
    fontSize: "14px",
    width: "100%"
  }
});
