import ContactDetails from "./contactDetails.js";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { add } from "redux/contact";
import { withStyles } from "@material-ui/core/styles";
import {
  getOfferIsLoading,
  getOfferLoaded,
  getOfferError
} from "redux/offer/selectors.js";
import { getContactDetails } from "redux/contact/selectors.js";
import styles from "./styles.js";

const mapStateToProps = (state, props) => {
  return {
    isLoading: getOfferIsLoading(state),
    loaded: getOfferLoaded(state),
    error: getOfferError(state),
    contactDetail: getContactDetails(state)
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = {
    add: bindActionCreators(add, dispatch)
  };
  return { actions };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ContactDetails));
