import React, { useRef, useState } from "react";
import { Grid, Typography, useTheme, useMediaQuery } from "@material-ui/core";
import Button from "components/NewSite/Button";
import { useFormik } from "formik";
import * as yup from "yup";
import classNames from "classnames";
import moment from "moment";
import Arrival from "components/NewSite/FormFields/Arrival";
import DatePicker from "components/NewSite/FormFields/DatePicker";
import { getDynamicFormValue } from "utils/helper";

const SearchFilters = ({
  classes,
  initialValues,
  dynamicFields = [],
  validation,
  onSubmit,
  disabled
}) => {
  const theme = useTheme();
  const matchesDownXs = useMediaQuery(theme.breakpoints.down("xs"));
  const fieldsRef = useRef(null);
  const [fieldMenuXsHeight, setFieldMenuXsHeight] = useState(225);

  const getDayAfter = date =>
    moment(date, "MM/DD/YY").add(1, "day").format("MM/DD/YY");
  const today = moment().format("MM/DD/YY");

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      arrival: initialValues.arrival ? initialValues.arrival : today,
      departure: initialValues.departure
        ? initialValues.departure
        : getDayAfter(today)
    },
    validationSchema: yup.object().shape({
      ...validation,
      arrival: yup.date().required(),
      departure: yup.date().required()
    }),
    onSubmit
  });

  const handleClickFieldsMenu = () =>
    setFieldMenuXsHeight(fieldsRef.current && fieldsRef.current.scrollHeight);

  const errorKeys = Object.keys(formik.errors);

  const departure = new Date(formik.values.departure);
  const arrival = new Date(formik.values.arrival);

  return (
    <Grid className={classes.root} container onClick={handleClickFieldsMenu}>
      <Grid
        ref={fieldsRef}
        container
        justify="flex-start"
        spacing={4}
        className={classes.fieldsContainer}
        style={{
          height: matchesDownXs ? fieldMenuXsHeight : "auto"
        }}
      >
        <Grid item xs={12} sm={6} md={3} className={classes.field}>
          <Arrival
            id="arrival"
            onChange={formik.handleChange}
            departure={departure}
            onArrivalGreaterThanDeparture={newArrival =>
              formik.setFieldValue("departure", getDayAfter(newArrival))
            }
            value={arrival}
            minDate={new Date(today)}
            format="MM/DD/YY"
            required
            error={!!formik.errors.arrival}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} className={classes.field}>
          <DatePicker
            id="departure"
            label="End"
            onChange={formik.handleChange}
            minDate={arrival}
            value={departure}
            format="MM/DD/YY"
            required
            error={!!formik.errors.departure}
          />
        </Grid>
        {dynamicFields.map(field => {
          const hasError = errorKeys[0] === field.id && formik.submitCount > 0;
          const value = getDynamicFormValue(formik, field);

          return (
            <Grid
              key={field.id}
              item
              xs={12}
              sm={6}
              md={3}
              className={classes.field}
            >
              {React.createElement(field.component, {
                ...field.props,
                value,
                onChange: formik.handleChange,
                error: hasError
              })}
            </Grid>
          );
        })}
      </Grid>

      <Grid container wrap={matchesDownXs ? "wrap" : "nowrap"}>
        <Grid className={classes.infoContainer}>
          <Typography className={classes.infoText}>
            Showing all current options. Please enter Arrival and Departure
            dates to get detailed availability.
          </Typography>

          {errorKeys.length > 0 && formik.submitCount > 0 ? (
            <Grid
              className={classNames(classes.feedback, classes.error)}
              container
              justify="center"
              alignItems="center"
            >
              {formik.errors[errorKeys[0]]}
            </Grid>
          ) : (
            <Grid
              className={classNames(classes.feedback, classes.formInfo)}
              container
              justify="center"
              alignItems="center"
            >
              Fields marked with (*) are mandatory.
            </Grid>
          )}
        </Grid>
        <Grid
          container
          justify="flex-end"
          alignItems="flex-end"
          style={{ flex: 1 }}
        >
          <Button
            className={classes.submitButton}
            disabled={disabled}
            onClick={() => formik.handleSubmit()}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

SearchFilters.displayName = "SearchFilters";

export default SearchFilters;
