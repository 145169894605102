import { Select as MaterialSelect, FormControl } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import Label from "../Label";

const Select = ({
  classes,
  label,
  onChange,
  value,
  id,
  helperText = "",
  error,
  options,
  required,
  ...rest
}) => {
  return (
    <FormControl fullWidth>
      {label && (
        <Label error={error} required={required}>
          {label}
        </Label>
      )}
      <MaterialSelect
        error={error}
        id={id}
        value={value}
        onChange={onChange(id)}
        variant="outlined"
        className={classes.commonInput}
        required={required}
        {...rest}
      >
        {options.map(option => (
          <MenuItem value={option.id} key={option.id}>
            {option.label}
          </MenuItem>
        ))}
      </MaterialSelect>
    </FormControl>
  );
};

export default Select;
