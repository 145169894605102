import * as SIZE from "utils/constants.js";

export default theme => ({
  paper: {
    border: `1px solid transparent`,
    flexDirection: "column",
    display: "flex",
    borderRadius: "10px",
    marginBottom: "35px",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      flexDirection: "column",
      padding: "20px 0"
    }
  },
  row: {
    display: "flex",
    padding: "35px",
    flexDirection: "column",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      display: "block"
    }
  },
  title: {},
  item: {
    display: "flex",
    flex: 1,
    padding: "5px"
  },
  itemName: {
    display: "flex",
    flex: 1,
    marginRight: "20px"
  },
  itemPrice: {
    display: "flex",
    width: "75px",
    alignItems: "center",
    color: theme.palette.primary.main,
    marginRight: "5px",
    fontSize: "20px"
  },
  divider: {
    margin: "10px 5px"
  }
});
