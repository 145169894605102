import { useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import GridItemDesktop from "./GridItemDesktop";
import GridItemMobile from "./GridItemMobile";

const GridItem = ({ background, name, classes, className, onViewDetails }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      {matches ? (
        <GridItemDesktop
          background={background}
          name={name}
          classes={classes}
          className={className}
          onViewDetails={onViewDetails}
        />
      ) : (
        <GridItemMobile
          background={background}
          name={name}
          classes={classes}
          className={className}
          onViewDetails={onViewDetails}
        />
      )}
    </>
  );
};

export default GridItem;
