import BookingComplete from "./bookingComplete.js";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { resetSearchStore } from "redux/search/index.js";
import { resetOfferStore } from "redux/offer/index.js";
import { resetCreditCardStore } from "redux/payment/index.js";
import { resetExtraStore } from "redux/extra/index.js";
import { withStyles } from "@material-ui/core/styles";
import { getFilters } from "redux/search/selectors.js";
import { getContactMail } from "redux/contact/selectors.js";
import { getContactDetails } from "redux/contact/selectors.js";
import { getBoatDetails } from "redux/boat/selectors.js";
import { getSelectedOffer } from "redux/offer/selectors.js";
import {
  getSelectedExtraPrice,
  getSelectedExtraTax
} from "redux/extra/selectors.js";
import {
  getBookingReference,
  getPaymentIsLoading
} from "redux/payment/selectors.js";
import { getMarinaName } from "redux/marina/selectors.js";
import styles from "./styles.js";

const mapStateToProps = (state, props) => {
  return {
    filter: getFilters(state),
    marinaName: getMarinaName(state),
    contactDetail: getContactDetails(state),
    contactMail: getContactMail(state),
    boatDetail: getBoatDetails(state),
    selectedOffer: getSelectedOffer(state),
    selectedExtraPrice: getSelectedExtraPrice(state),
    selectedExtraTax: getSelectedExtraTax(state),
    isLoading: getPaymentIsLoading(state),
    bookingReference: getBookingReference(state)
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = {
    resetOfferStore: bindActionCreators(resetOfferStore, dispatch),
    resetExtraStore: bindActionCreators(resetExtraStore, dispatch),
    resetSearchStore: bindActionCreators(resetSearchStore, dispatch),
    resetCreditCardStore: bindActionCreators(resetCreditCardStore, dispatch)
  };
  return { actions };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BookingComplete));
