import Offer from "./offer.js";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { searchOffer, add, searchMap } from "redux/offer";
import { addExtra } from "redux/extra";
import { withStyles } from "@material-ui/core/styles";
import { getFilters } from "redux/search/selectors.js";
import {
  getOfferIsLoading,
  getOfferLoaded,
  getOfferHolded,
  getOfferError,
  getOfferList,
  getSelectedOffer
} from "redux/offer/selectors.js";
import { getMarinaDetails } from "redux/marina/selectors.js";
import { getExtraList } from "redux/extra/selectors.js";
import { resetExtraStore } from "redux/extra/index.js";
import styles from "./styles.js";

const mapStateToProps = (state, props) => {
  return {
    filter: getFilters(state),
    isLoading: getOfferIsLoading(state),
    loaded: getOfferLoaded(state),
    holded: getOfferHolded(state),
    error: getOfferError(state),
    offerList: getOfferList(state),
    extraList: getExtraList(state),
    marinaDetails: getMarinaDetails(state),
    selectedOffer: getSelectedOffer(state)
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = {
    search: bindActionCreators(searchOffer, dispatch),
    add: bindActionCreators(add, dispatch),
    addExtra: bindActionCreators(addExtra, dispatch),
    resetExtraStore: bindActionCreators(resetExtraStore, dispatch),
    searchMap: bindActionCreators(searchMap, dispatch)
  };
  return { actions };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Offer));
