import * as SIZE from "utils/constants.js";

export default theme => ({
  root: {
    textAlign: "center",
    fontSize: "0.7rem",
    margin: "1% 8%",
    paddingTop: "10px",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      minWidth: "300px",
      margin: "1%"
    }
  },
  extraHeight: {
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      paddingTop: "20px",
      paddingBottom: "165px"
    }
  },
  typography: {
    fontSize: "12px",
    fontFamily: "'Maven Pro', sans-serif"
  },
  a11yContainer: {
    marginTop: 5
  }
});
