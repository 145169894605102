import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";

const Error = ({ classes, message }) => {
  return (
    <div className={classes.submittedWithError}>
      <Typography variant="h6" className={classes.error}>
        {message}
      </Typography>
    </div>
  );
};

Error.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired
};

Error.defaultProps = {};

Error.displayName = "Error";

export default Error;
