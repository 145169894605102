import { Grid, Typography } from "@material-ui/core";
import React from "react";
import Button from "@material-ui/core/Button";
import classNames from "classnames";

const GridItemMobile = ({
  background,
  name,
  classes,
  className,
  onViewDetails
}) => {
  return (
    <Grid
      container
      direction="column"
      justify="space-between"
      className={classNames(classes.mobileRoot, className)}
    >
      <Grid
        item
        style={{
          backgroundImage: `url(${background})`,
          backgroundColor: "grey"
        }}
        className={classes.mobilePhoto}
      />
      <Typography variant="h6" className={classes.mobileName}>
        {name}
      </Typography>
      <Button
        className={classes.detailsButton}
        onClick={() => onViewDetails(name)}
      >
        View Details
      </Button>
    </Grid>
  );
};

export default GridItemMobile;
