import React, { useState, useCallback, useMemo } from "react";
import Carousel from "react-slick";
import rigthArrow from "assets/icons/right-arrow.svg";
import leftArrow from "assets/icons/left-arrow.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useMediaQuery, useTheme } from "@material-ui/core";
import ModalCarrousel, { Modal, ModalGateway } from "react-images"

const NextArrow = ({ className, onClick }) => {
  return (
    <img
      src={rigthArrow}
      alt="rightarrow"
      className={className}
      onClick={onClick}
    />
  );
};

const PrevArrow = ({ className, onClick }) => {
  return (
    <img
      src={leftArrow}
      alt="leftarrow"
      className={className}
      onClick={onClick}
    />
  );
};

const PhotoGallery = ({ photos: parentPhotos }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const [lightboxStatus, setLightboxStatus] = useState({
    isOpen: false,
    selectedIndex: 0,
  });

  const photos = useMemo(() => {
    return parentPhotos.map((photo, index) => ({ 
      source: {
        regular: photo,
        thumbnail: photo.replace(/.*\.com\/(.+)/, (_, imageKey) => `https://cdn.filestackcontent.com/resize=width:400/${imageKey}`),
        fullscreen: photo
      },
        key: index }
    ));
  }, [parentPhotos])

  const handleCloseLightbox = useCallback(() => {
    setLightboxStatus({ isOpen: false, selectedIndex: 0 });
  }, [setLightboxStatus])

  const handleOpenLightbox = useCallback((pIndex) => () => {
    setLightboxStatus({ isOpen: true, selectedIndex: pIndex });
  }, [setLightboxStatus])

  return (
    <>
      <Carousel
        dots={false}
        infinite
        arrows={!matches}
        slidesToScroll={1}
        variableWidth
        nextArrow={<NextArrow />}
        prevArrow={<PrevArrow />}
      >
        {photos.map(photo => (
          <div key={photo.key} className="container-photo" onClick={handleOpenLightbox(photo.key)}>
            <img src={photo.source.thumbnail} alt="photogallery" />
          </div>
        ))}
      </Carousel>

      <ModalGateway>
        {lightboxStatus.isOpen ? (
          <Modal onClose={handleCloseLightbox}>
            <ModalCarrousel
              currentIndex={lightboxStatus.selectedIndex}
              views={photos}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  );
};

PhotoGallery.displayName = "Photo Gallery";

export default PhotoGallery;
