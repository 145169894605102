import React from "react";
import moment from "moment";
import DatePicker from "../DatePicker";

const Arrival = ({
  label,
  onChange,
  onArrivalGreaterThanDeparture,
  departure,
  value,
  format,
  minDate,
  maxDate,
  required,
  error,
  id
}) => {
  return (
    <DatePicker
      label={label || "Start"}
      id={id}
      required={required}
      error={error}
      onChange={e => {
        onChange(e);

        const newDate = e.target.value;

        if (new Date(newDate) >= new Date(departure)) {
          onArrivalGreaterThanDeparture(newDate);
        }
      }}
      value={value}
      minDate={minDate || new Date(moment().format("MM/DD/YY"))}
      maxDate={maxDate}
      format={format || "MM/DD/YY"}
    />
  );
};

Arrival.displayName = "Arrival";

export default Arrival;
