import { get } from "lodash";
import moment from "moment";
import { DATE_FORMAT } from "utils/constants.js";

const parseBoat = boatDetail => {
  if (
    get(boatDetail, "expirationDate.value") &&
    moment(get(boatDetail, "expirationDate.value"), DATE_FORMAT).isValid() &&
    moment().isSameOrAfter(
      moment(get(boatDetail, "expirationDate.value"), DATE_FORMAT)
    )
  ) {
    delete boatDetail.expirationDate;
  }
  return boatDetail;
};

export const getContactDetails = () =>
  JSON.parse(window.localStorage.getItem("contact"));

export const getBoatDetails = () =>
  parseBoat(JSON.parse(window.localStorage.getItem("boat")));

export const setContactDetails = contactDetail =>
  window.localStorage.setItem("contact", JSON.stringify(contactDetail));

export const setBoatDetails = boatDetail =>
  window.localStorage.setItem("boat", JSON.stringify(boatDetail));
