import Payment from "./payment.js";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { add } from "redux/payment";
import { add as addToOffer } from "redux/offer";
import { withStyles } from "@material-ui/core/styles";
import { getFilters } from "redux/search/selectors.js";
import {
  getSelectedOffer,
  getSelectedProperties
} from "redux/offer/selectors.js";
import {
  getSelectedExtraList,
  getSelectedExtraPrice,
  getSelectedExtraTax
} from "redux/extra/selectors.js";
import { getContactDetails } from "redux/contact/selectors.js";
import {
  getPaymentIsLoading,
  getPaymentDetails,
  getPaymentClientSecret
} from "redux/payment/selectors.js";
import { getMarinaName, getMarina } from "redux/marina/selectors.js";
import "./styles.css";
import styles from "./styles.js";

const mapStateToProps = (state, props) => {
  return {
    filter: getFilters(state),
    marina: getMarina(state),
    marinaName: getMarinaName(state),
    selectedOffer: getSelectedOffer(state),
    selectedExtra: getSelectedExtraList(state),
    selectedExtraPrice: getSelectedExtraPrice(state),
    selectedExtraTax: getSelectedExtraTax(state),
    contactDetails: getContactDetails(state),
    isLoading: getPaymentIsLoading(state),
    paymentDetails: getPaymentDetails(state),
    selectedOfferProperties: getSelectedProperties(state),
    clientSecret: getPaymentClientSecret(state)
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = {
    add: bindActionCreators(add, dispatch),
    addToOffer: bindActionCreators(addToOffer, dispatch)
  };
  return { actions };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Payment));
