import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Link, Redirect } from "react-router-dom";
import { isEmpty, get } from "lodash";
import Button from "@material-ui/core/Button";
import SplashScreen from "components/Shared/SplashScreen";
import TagManager from "react-gtm-module";
import * as ROUTES from "utils/constants.js";

const BookingComplete = ({
  classes,
  actions,
  isLoading,
  filter,
  contactDetail,
  boatDetail,
  contactMail,
  selectedOffer,
  selectedExtraPrice,
  selectedExtraTax,
  offer,
  marinaName,
  bookingReference
}) => {
  const tagManagerArgs = {
    dataLayer: {
      arrivalDate: get(filter, "arrival.value", ""),
      departureDate: get(filter, "departure.value", ""),
      vesselLOA: get(filter, "length.value"),
      vesselBeam: get(filter, "beam.value"),
      vesselDraft: get(filter, "draft.value"),
      offerID: get(selectedOffer, "offerID"),
      firstName: get(contactDetail, "firstName.value"),
      lastName: get(contactDetail, "lastName.value"),
      emailAddress: get(contactDetail, "email.value"),
      vesselName: get(boatDetail, "boatName.value"),
      minimumDeposit: get(selectedOffer, "minimumPayment"),
      reservationTotal:
        parseFloat(selectedExtraPrice, 10) +
        parseFloat(selectedExtraTax, 10) +
        selectedOffer.offerTotal
    },
    dataLayerName: "PaymentLayer"
  };

  // This will get the offer.type before the reset-actions do theirs jobs
  const [redirectRoute] = useState(() => {
    const type = get(selectedOffer, "type");

    if (type === ROUTES.OFFER_TYPES.RESOURCE) {
      return ROUTES.RESOURCES
    } else if(type === ROUTES.OFFER_TYPES.DOCKAGE) {
      return ROUTES.SEARCH;
    }
  });
  
  !isEmpty(bookingReference) && TagManager.dataLayer(tagManagerArgs);

  useEffect(() => {
    actions.resetSearchStore();
    actions.resetOfferStore();
    actions.resetExtraStore();
    return () => actions.resetCreditCardStore();
  }, [actions]);

  if (isLoading) return <SplashScreen animation="lighthouse" />;

  return (
    <>
      {isEmpty(bookingReference) && <Redirect to={ROUTES.HOME} />}
      <Paper className={classes.root} elevation={1}>
        <Typography variant="h6" style={{ margin: "2rem 0" }}>
          Thank you for booking with {marinaName}!
        </Typography>
        <Typography variant="body1" style={{ margin: "1.5rem 0" }}>
          We’ll send you an email with booking details to {contactMail}
        </Typography>
        <Typography variant="body1" style={{ margin: "1.5rem 0" }}>
          Your booking reference is:
        </Typography>
        <Typography color="primary" variant="h6" style={{ margin: "1.5rem 0" }}>
          {bookingReference}
        </Typography>
        <Typography variant="body1" style={{ margin: "1.5rem 0" }}>
          We look forward to seeing you soon!
        </Typography>
        <Button
          id="make-another-booking"
          variant="contained"
          color="primary"
          className={classes.button}
        >
          <Link
            className={classes.link}
            to={{
              pathname: redirectRoute,
            }}
          >
            Make Another Booking
          </Link>
        </Button>
      </Paper>
    </>
  );
};

BookingComplete.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  contactDetail: PropTypes.object.isRequired,
  boatDetail: PropTypes.object.isRequired,
  selectedOffer: PropTypes.object.isRequired,
  contactMail: PropTypes.string.isRequired,
  selectedExtraPrice: PropTypes.number,
  selectedExtraTax: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
  marinaName: PropTypes.string.isRequired,
  bookingReference: PropTypes.string.isRequired
};

BookingComplete.defaultProps = {
  contactMail: "N/A",
  bookingReference: "N/A",
  marinaName: "Online Marina",
  selectedExtraPrice: "",
  selectedExtraTax: "",
  isLoading: true
};

BookingComplete.displayName = "BookingComplete";

export default BookingComplete;
