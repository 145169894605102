import * as SIZE from "utils/constants.js";

export default (theme) => ({
  container: { flex: "1" },
  paper: {
    padding: "60px",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      maxWidth: "100%",
      padding: "25px",
    },
  },
  submittedWithError: {
    border: "1px solid red",
    padding: "15px",
    borderRadius: "6px",
    margin: "16px 0px",
    textAlign: "center",
  },
  error: {
    color: "red",
  },
  overlay: {
    position: "relative",
    width: "100%",
    left: "0",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      width: "80%",
      margin: "0 auto",
    },
  },
  loading: {
    opacity: 0,
    display: "none",
  },
  cardError: {
    color: "#c72223",
  },
  price: {
    color: theme.palette.primary.main,
    marginRight: "5px",
    fontSize: "20px",
    width: 75
  },
});
