export default theme => ({
  title: {
    padding: "40px 0",
    display: "flex",
    alignItems: "center"
  },
  icon: {
    margin: theme.spacing(),
    marginLeft: 0,
    fontSize: 24,
    color: theme.palette.primary.main,
    borderRadius: "25px",
    border: `2px solid ${theme.palette.primary.main}`,
    padding: "3px"
  }
});
