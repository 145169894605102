import { Grid } from "@material-ui/core";
import React from "react";
import Button from "@material-ui/core/Button";
import classNames from "classnames";

const GridItemDesktop = ({
  background,
  name,
  classes,
  className,
  onViewDetails
}) => {
  return (
    <Grid
      container
      alignItems="flex-end"
      style={{
        backgroundImage: `url(${background})`,
        backgroundColor: "grey"
      }}
      className={classNames(classes.root, className)}
    >
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.details}
        id="details"
      >
        <Grid className={classes.nameContent}>
          <span className={classes.name}>{name}</span>
        </Grid>
        <Button
          className={classes.detailsButton}
          id="details-button"
          onClick={() => onViewDetails(name)}
        >
          View Details
        </Button>
      </Grid>
    </Grid>
  );
};

export default GridItemDesktop;
