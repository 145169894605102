import { parseExtras } from "./utils";
import axios from "axios";
import { get } from "lodash";
import * as API from "redux/api";
import { remove } from "lodash";

//Action types
const ADD = "ADD";
const ADD_EXTRA_FROM_OFFER = "ADD_EXTRA_FROM_OFFER";
const RESET_EXTRA = "RESET_EXTRA";
const SELECT = "SELECT";

//Functions
export const searchExtra = filter => {
  return async dispatch => {
    try {
      dispatch(add({ loading: true }));
      // await new Promise(resolve => setTimeout(resolve, 1000));
      dispatch(add({ loading: false }));
    } catch (error) {
      dispatch(add({ loading: false }));
    }
  };
};

export const hold = (extras, offerId, marinaId) => {
  return async dispatch => {
    try {
      dispatch(add({ loading: true }));
      dispatch(add({ holded: false }));
      dispatch(add({ error: "" }));
      axios
        .post(API.holdExtra, {
          MarinaId: marinaId,
          OfferID: offerId,
          ExtraIds: extras
        })
        .then(response => {
          dispatch(add({ holded: true }));
        })
        .catch(error => {
          dispatch(add({ holded: true }));
          dispatch(
            add({ error: get(error, "response.data.Message", "Error") })
          );
        })
        .finally(() => {
          dispatch(add({ loading: false }));
        });
    } catch (error) {
      dispatch(add({ loading: false }));
    }
  };
};

//Action creators
export function add(payload) {
  return {
    type: ADD,
    payload
  };
}

export function addExtra(payload) {
  return {
    type: ADD_EXTRA_FROM_OFFER,
    payload
  };
}

export function select(payload) {
  return {
    type: SELECT,
    payload
  };
}

export function resetExtraStore() {
  return {
    type: RESET_EXTRA
  };
}

//Reducers
const initialState = {
  list: [],
  selected: [],
  error: "",
  holded: false,
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD:
      return { ...state, ...action.payload };
    case ADD_EXTRA_FROM_OFFER:
      return {
        ...state,
        list: parseExtras({ extras: action.payload.extras || [] }),
        selected: []
      };
    case SELECT:
      const stringIds = action.payload;

      if (!stringIds) {
        return { ...state };
      }

      const ids = stringIds.split(",");

      let newArray = [...state.selected];

      ids.forEach(id => {
        if (state.selected.find(i => i === id)) {
          remove(newArray, a => a === id);
        } else {
          newArray.push(id);
        }
      });

      return {
        ...state,
        selected: newArray
      };
    case RESET_EXTRA:
    case "RESET":
      return initialState;
    default:
      return state;
  }
};
