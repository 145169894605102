import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Typography } from "@material-ui/core";

const MapDialog = ({ open, handleClose, map, classes, marinaName, offer }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        paperWidthSm: classes.paper
      }}
    >
      <DialogTitle id="alert-dialog-title">{marinaName} Map</DialogTitle>
      <DialogContent
      className={classes.content}
      >
        {offer.map ? (
          <div
            className={classes.mapContainer}
            dangerouslySetInnerHTML={{
              __html: offer.map
            }}
          />
        ) : (
          <Typography>There is no map yet.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button className={classes.button} onClick={handleClose} color="primary" autoFocus>
          Close Map
        </Button>
      </DialogActions>
    </Dialog>
  );
};

MapDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  map: PropTypes.string.isRequired
};

MapDialog.displayName = "MapDialog";

export default MapDialog;
