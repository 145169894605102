export const values = [
  { id: 1, value: "120V Single 20 Amp", PowerNeed1: "120 V, 20 Amps" },
  { id: 2, value: "120V Single 30 Amp", PowerNeed1: "120 V, 30 Amps" },
  {
    id: 3,
    value: "120V/240V Double 30 Amp",
    PowerNeed1: "120 V, 30 Amps",
    PowerNeed2: "120 V, 30 Amps"
  },
  { id: 4, value: "120V Single 50 Amp", PowerNeed1: "120 V, 50 Amps" },
  {
    id: 5,
    value: "120V/240V Double 50 Amp",
    PowerNeed1: "120/240 V, 50 Amps",
    PowerNeed2: "120/240 V, 50 Amps"
  },
  {
    id: 6,
    value: "120V/240V Single 100 Amp 1 Phase",
    PowerNeed1: "120/240 V, 100 Amps"
  },
  {
    id: 7,
    value: "120V/208V Single 100 Amp 3 Phase",
    PowerNeed1: "120/208 V, 100 Amps, 3 Phase"
  },
  { id: 8, value: "240V 200 Amps", PowerNeed1: "240 V 200 Amps" },
  { id: 9, value: "480V Single Phase", PowerNeed1: "480 V, Single Phase" },
  { id: 10, value: "480V 3 Phase", PowerNeed1: "480 V, 3 Phase" }
];

export default values;
