import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { get } from "lodash";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import * as ROUTES from "utils/constants.js";

const Home = ({ classes, actions, marina }) => {
  useEffect(() => {
    actions.resetStore();
  }, [actions]);
  return (
    <Fragment>
      <div className={classes.col} />
      <div className={classes.selector}>
        <Paper className={classes.root} elevation={1}>
          <Typography className={classes.fontTitle} variant="h4">
            Welcome to {get(marina, "data.MarinaName")} Booking!
          </Typography>
          <Typography className={classes.fontSubTitle} variant="h5">
            What type of dockage are you looking for?
          </Typography>
          <div className={classes.buttons}>
            <Button
              id="short-term"
              variant="contained"
              color="primary"
              className={classes.button}
            >
              <Link
                className={classes.link}
                to={{
                  pathname: ROUTES.SEARCH,
                  data: "Short-Term"
                }}
              >
                Short-Term
              </Link>
            </Button>
            <Button
              id="seasonal"
              variant="contained"
              color="primary"
              className={classes.button}
            >
              <Link
                className={classes.link}
                to={{
                  pathname: ROUTES.SEARCH,
                  data: "Seasonal"
                }}
              >
                Seasonal
              </Link>
            </Button>
            <Button
              id="annual"
              variant="contained"
              color="primary"
              className={classes.button}
            >
              <Link
                className={classes.link}
                to={{
                  pathname: ROUTES.SEARCH,
                  data: "Annual"
                }}
              >
                Annual
              </Link>
            </Button>
          </div>
        </Paper>
      </div>
    </Fragment>
  );
};

Home.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  marina: PropTypes.object.isRequired
};

Home.displayName = "Home";

export default Home;
