import { lowerCase } from "lodash";
import { CUSTOM_FIELDS } from "utils/constants";

export const parseCustomFields = (customFields = []) => {
  return customFields.reduce((acc, cField) => {
    const newCField = {};

    Object.keys(cField).forEach(key => {
      const newKey = lowerCase(key);

      if (newKey === "type") {
        switch (cField[key]) {
          case "String":
            newCField[newKey] = CUSTOM_FIELDS.TEXT;
            return;

          case "Numeric":
            newCField[newKey] = CUSTOM_FIELDS.NUMBER;
            return;

          case "Date":
            newCField[newKey] = CUSTOM_FIELDS.DATE;
            return;

          case "Single Select":
            newCField[newKey] = CUSTOM_FIELDS.SELECT;
            return;

          case "Multiple Select":
            newCField[newKey] = CUSTOM_FIELDS.MULTISELECT;
            return;

          default:
            break;
        }
      }

      if (newKey === "options") {
        const newOptions = cField[key].reduce((acc2, option) => {
          const newOption = {};

          Object.keys(option).forEach(key => {
            const newOptionKey = lowerCase(key);

            if (newOptionKey === "name") {
              newOption.label = option[key];

              return;
            }

            newOption[newOptionKey] = option[key];
          });

          acc2.push(newOption);

          return acc2;
        }, []);

        newCField[newKey] = newOptions;

        return;
      }

      if (newKey === "id") {
        newCField[newKey] = cField[key].toString();

        return;
      }

      if (newKey === "name") {
        newCField.label = cField[key];

        return;
      }

      newCField[newKey] = cField[key];
    });

    acc.push(newCField);

    return acc;
  }, []);
};
