import Error from "./Error";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

const mapStateToProps = (state, props) => {
  return {};
};
const mapDispatchToProps = (dispatch, props) => {
  const actions = {};

  return { actions };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Error));
