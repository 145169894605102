import App from "./App.js";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { searchMarina } from "redux/marina";
import { withRouter } from "react-router-dom";

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = {
    searchMarina: bindActionCreators(searchMarina, dispatch)
  };
  return { actions };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
