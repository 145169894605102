import React from "react";
// import { Link } from "react-router-dom";
import { get } from "lodash";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import logo from "assets/img/molo-icon.png";
import classNames from "classnames";

const Header = ({ classes, marina, showBorder }) => {
  return (
    <div
      className={classNames({
        [classes.root]: true,
        [classes.border]: showBorder
      })}
    >
      <AppBar className={classes.appBar} position="static" color="default">
        <Toolbar className={classes.toolbar}>
          <div className={classes.logo}>
            <img
              className={classes.image}
              src={get(marina, "data.LogoUrl") || logo}
              alt={`Logo of ${get(marina, "data.MarinaName", "Molo Marina")}`}
            />
            <Typography className={classes.fontTitle} variant="h5">
              {get(marina, "data.MarinaName") || "Molo Marina"}
            </Typography>
          </div>
          <Button variant="outlined" className={classes.button}>
            <a
              className={classes.link}
              href={get(marina, "data.MarinaHomeUrl") || "https://getmolo.com"}
              // target="_blank"
              rel="noopener noreferrer"
            >
              Back to Site
            </a>
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  marina: PropTypes.object.isRequired,
  showBorder: PropTypes.bool
};

Header.defaultProps = {
  showBorder: false
};

export default Header;
