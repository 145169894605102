import * as SIZE from "utils/constants";

export default theme => ({
  root: {
    padding: "0 20px",
    margin: "0 auto",
    background: "white",
    marginBottom: 30,
    width: "100%",
    boxSizing: "border-box",

    [theme.breakpoints.down(SIZE.XSMALL)]: {
      padding: "0 10px"
    }
  },
  galleryTitle: {
    color: "#2A333C",
    fontFamily: "Maven Pro",
    fontSize: 32,
    fontWeight: "bold",
    lineHeight: "38px",
    textAlign: "center",
    marginBottom: 40,
    marginTop: 56,

    [theme.breakpoints.down(SIZE.SMALL)]: {
      textAlign: "left",
      fontSize: 24,
      marginTop: 0,
      marginBottom: 24
    }
  },
  galleryContainer: {
    maxWidth: 1128,
    margin: "0 auto",
    [theme.breakpoints.down(SIZE.SMALL)]: {
      paddingLeft: 10
    }
  },
  breadcrumbsContainer: {
    marginTop: 68,
    marginBottom: 56,
    margin: "68px auto 56px auto",
    maxWidth: 1128,

    [theme.breakpoints.down(SIZE.SMALL)]: {
      marginTop: 20,
      marginBottom: 24,
      maxWidth: "100%"
    }
  },
  mainContent: {
    maxWidth: 1128,
    margin: "0 auto",
    [theme.breakpoints.down(SIZE.SMALL)]: {
      "& > div": {
        marginBottom: 40
      }
    }
  },
  offerPickerContainer: {
    marginTop: 40
  },
  errorContainer: {
    marginTop: 40,
    borderRadius: 6,
    backgroundColor: "#C72323",
    color: "#FFFFFF",
    fontFamily: "Maven Pro",
    fontSize: 14,
    padding: "8px 16px",

    [theme.breakpoints.down(SIZE.SMALL)]: {
      padding: "7px 16px"
    }
  }
});
