import { get, isEmpty } from "lodash";

export const getExtra = state => get(state, "extra", {});
export const getExtraIsLoading = state => get(state, "extra.loading");
export const getExtraHolded = state => get(state, "extra.holded");
export const getExtraError = state => get(state, "extra.error");
export const getExtraList = state => get(state, "extra.list", []);
export const getSelectedExtra = state => get(state, "extra.selected", []);
export const getSelectedExtraList = state => {
  const extraList = get(state, "extra.list", []);
  const selectedExtra = get(state, "extra.selected", []);
  const extras = extraList.filter(function (e) {
    return this.indexOf(e.id) >= 0;
  }, selectedExtra);
  return extras;
};
export const getSelectedExtraPrice = state => {
  const list = getSelectedExtraList(state);
  if (isEmpty(list)) return 0;
  const extraAmount = list
    .filter(item => item.required === false)
    .map(i => i.total)
    .reduce((previous, current) => {
      return previous + current;
    }, 0);
  return extraAmount;
};

export const getSelectedExtraTax = state => {
  const list = getSelectedExtraList(state);
  if (isEmpty(list)) return 0;
  const extraAmount = list
    .filter(item => item.required === false)
    .map(i => i.tax)
    .reduce((previous, current) => {
      return previous + current;
    }, 0);
  return extraAmount || 0;
};

export const getAllSelectedExtraListAmount = state => {
  const list = getSelectedExtraList(state);
  if (isEmpty(list)) return 0;
  const extraAmount = list
    .map(i => i.total)
    .reduce((previous, current) => {
      return previous + current;
    }, 0);
  return extraAmount;
};
