import React from "react";
import classNames from "classnames/bind";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import * as ROUTES from "utils/constants.js";

const Footer = ({ classes, location, offerList, marinaName }) => {
  const cx = classNames.bind(classes);
  const extraHeight =
    (location.pathname === ROUTES.OFFERS && !isEmpty(offerList)) ||
    location.pathname === ROUTES.EXTRAS ||
    location.pathname === ROUTES.CONTACT_DETAILS ||
    location.pathname === ROUTES.BOAT_DETAILS ||
    location.pathname === ROUTES.PAYMENT;

  return (
    <div className={cx("root", { extraHeight })}>
      <div className={classes.typography}>
        Powered by{" "}
        <a href="https://getmolo.com" target="_blank" rel="noopener noreferrer">
          Molo
        </a>
        {marinaName && (
          <>
            {" "}
            - {marinaName} has partnered with Molo to provide online
            reservations for their business. Molo is not responsible for the
            information displayed here nor any inaccuracies contained within.
          </>
        )}
      </div>
      <div className={classes.a11yContainer}>
        <a
          href="https://getmolo.com/assets/Molo_Accessibility_Statement_10-Mar-2021.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Here is our accessibility statement.
        </a>
      </div>
    </div>
  );
};

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  marinaName: PropTypes.string.isRequired,
  location: PropTypes.object
};

Footer.defaultProps = {
  marinaName: "Online Marina",
  location: {}
};

Footer.displayName = "Footer";

export default Footer;
