import NoResults from "./NoResults";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getMarinaDetails } from "redux/marina/selectors.js";
import { getOfferPostLogSetId } from "redux/offer/selectors";
import { getIsLoading, getIsLoaded, getData } from "redux/email/selectors";
import { submit } from "redux/email";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";

const mapStateToProps = (state, props) => {
  return {
    offerPostLogSetId: getOfferPostLogSetId(state),
    isLoading: getIsLoading(state),
    isLoaded: getIsLoaded(state),
    marina: getMarinaDetails(state),
    data: getData(state)
  };
};
const mapDispatchToProps = (dispatch, props) => {
  const actions = {
    submit: bindActionCreators(submit, dispatch)
  };

  return { actions };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(NoResults));
