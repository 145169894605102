import { Select as MaterialSelect, FormControl } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { findIndex, get } from "lodash";
import Label from "../Label";

const Multiselect = ({
  classes,
  label,
  onChange,
  value,
  id,
  helperText = "",
  error,
  options,
  required,
  ...rest
}) => {
  const getRenderValue = selected =>
    selected
      .map(
        id =>
          get(options, [findIndex(options, option => option.id === id)], {})
            .label
      )
      .join(", ");

  return (
    <FormControl fullWidth>
      {label && (
        <Label error={error} required={required}>
          {label}
        </Label>
      )}
      <MaterialSelect
        multiple
        error={error}
        id={id}
        value={value}
        onChange={onChange(id)}
        variant="outlined"
        className={classes.commonInput}
        renderValue={getRenderValue}
        required={required}
        {...rest}
      >
        {options.map(option => (
          <MenuItem value={option.id} key={option.id}>
            <Checkbox
              color="primary"
              classes={{
                colorPrimary: classes.checkbox,
                checked: classes.checkbox
              }}
              checked={value.indexOf(option.id) > -1}
            />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </MaterialSelect>
    </FormControl>
  );
};

export default Multiselect;
