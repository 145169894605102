import * as SIZE from "utils/constants.js";

export default theme => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      backgroundColor: "#F5F5F5"
    }
  },
  backgroundDesktop: {
    backgroundColor: "#F5F5F5",
    height: "40vh",
    margin: "0 6%",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      display: "none"
    }
  },
  backgroundImageDesktop: {
    backgroundPosition: "center",
    backgroundSize: "cover",
    width: "100%",
    minHeight: "100%",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      display: "none"
    }
  },
  companyImgMobile: {
    display: "none",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      display: "flex",
      width: "100%"
    }
  },
  breadcrumbsContainer: {
    padding: "0 156px",
    marginTop: 68,
    marginBottom: 56
  },
  hidden: {
    display: "none"
  }
});
