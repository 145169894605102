export const parseExtras = ({ extras }) => extras.map(parseExtra);

export const parseExtra = ({
  Id,
  AddonName,
  Required,
  PricingMethod,
  UnitPrice,
  Total,
  Description,
  Tax
}) => ({
  id: Id,
  addonName: AddonName,
  required: Required,
  pricingMethod: PricingMethod,
  unitPrice: UnitPrice,
  total: Total,
  description: Description,
  tax: Tax
});
