import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { OFFER_TYPES } from "utils/constants";
import { Grid } from "@material-ui/core";
import { getPages } from "utils/helper";

const Breadcrumb = ({
  classes,
  match,
  location,
  offerType,
  showOnMobile,
  pathname,
  selectedOffer,
  selectedPage,
  history,
  ...rest
}) => {
  const pages = useMemo(() => {
    if (offerType === OFFER_TYPES.RESOURCE && selectedOffer.name) {
      const dynamicPage = {
        position: 1,
        value: selectedOffer.name,
        url: "",
        nextUrl: ""
      };

      return getPages(offerType, dynamicPage);
    }

    return getPages(offerType);
  }, [offerType, selectedOffer.name]);

  return (
    <Breadcrumbs
      className={classNames({
        [classes.root]: true,
        [classes.showOnMobile]: showOnMobile
      })}
      separator={<NavigateNextIcon fontSize="small" />}
      arial-label="Breadcrumb"
    >
      {pages.map((page, i) => (
        <Grid
          key={i}
          className={
            page.url === location.pathname || page.value === selectedPage
              ? classes.selected
              : ""
          }
        >
          <Typography color="textPrimary" variant="body1">
            {page.value}
          </Typography>
        </Grid>
      ))}
    </Breadcrumbs>
  );
};

Breadcrumb.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  location: PropTypes.object
};

Breadcrumb.displayName = "Breadcrumb";

export default Breadcrumb;
