import * as SIZE from "utils/constants.js";

export default theme => ({
  paper: {
    padding: "10%",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      padding: "3% 8%"
    }
  },
  search: {
    backgroundColor: "transparent",
    boxShadow: "none",
    border: "1px solid gray",
    padding: "25px",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      display: "none"
    }
  },
  extraHeight: {
    marginTop: "40px"
  },
  typography: {
    marginTop: "0.5em",
    textAlign: "center"
  },
  button: {
    backgroundColor: "#5cb85c",
    width: "100%",
    fontSize: "14px",
    padding: "15px",
    marginBottom: "0.35em",
    "&:hover": {
      backgroundColor: "#47a447"
    }
  },
  buttonSearch: {
    width: "100%",
    fontSize: "14px",
    padding: "15px",
    marginTop: "1.5em",
    marginBottom: "0.35em"
  }
});
