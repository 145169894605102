import Resources from "./Resources.js";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import styles from "./styles.js";
import { bindActionCreators } from "redux";
import {
  searchMarinaResources,
  setOfferTypeAsResource,
  add,
  addToSelected
} from "redux/offer/index.js";

import { add as addFilters } from "redux/search/index.js";

import { getMarinaDetails } from "redux/marina/selectors";

import { getMarinaIsLoaded } from "redux/marina/selectors.js";
import {
  getOfferList,
  getOfferLoaded,
  getOfferIsLoading,
  getOfferError,
} from "redux/offer/selectors.js";

import { getFilters } from "redux/search/selectors.js";

const mapDispatchToProps = dispatch => {
  const actions = {
    searchMarinaResources: bindActionCreators(searchMarinaResources, dispatch),

    setOfferTypeAsResource: bindActionCreators(
      setOfferTypeAsResource,
      dispatch
    ),
    add: bindActionCreators(add, dispatch),
    addToSelected: bindActionCreators(addToSelected, dispatch),
    addFilters: bindActionCreators(addFilters, dispatch)
  };
  return { actions };
};

const mapStateToProps = state => {
  return {
    offerList: getOfferList(state),
    isMarinaLoaded: getMarinaIsLoaded(state),
    isOfferLoaded: getOfferLoaded(state),
    isOfferSearchingAgain: getOfferIsLoading(state),
    marinaDetails: getMarinaDetails(state),
    filters: getFilters(state),
    error: getOfferError(state),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Resources));
