import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { get, startCase } from "lodash";
import { Route, Redirect } from "react-router-dom";
import Header from "components/Layout/Header";
import Footer from "components/Layout/Footer";
import SplashScreen from "components/Shared/SplashScreen";
import { BUSINESS_LIST } from "utils/constants.js";
import classNames from "classnames";

const HomeLayout = ({
  component: Component,
  classes,
  marina,
  isLoaded,
  showBackground,
  offerType,
  showBreadcrumbs,
  showBorder,
  styles,
  ...rest
}) => {
  const [childrenIsLoading, setChildrenIsLoading] = useState(false);

  const allyText = `Picture of ${get(marina, "data.MarinaName", "Marina")}`;

  useEffect(() => {
    document.title = `${startCase(
      get(marina, "data.MarinaName")
    )} Online Booking`;
  }, [isLoaded, marina]);

  return (
    <>
      {isLoaded && !get(marina, "data.MarinaName") && (
        <Redirect to={BUSINESS_LIST} />
      )}
      {(!isLoaded || childrenIsLoading) && (
        <SplashScreen absolute animation="buoy" />
      )}
      <Route
        {...rest}
        render={matchProps => (
          <div
            className={classNames({
              [classes.root]: true,
              [classes.hidden]: !isLoaded || childrenIsLoading
            })}
            style={styles}
          >
            <Header showBorder={showBorder} />
            {showBackground && (
              <>
                <div className={classes.backgroundDesktop}>
                  <div
                    role="img"
                    title={allyText}
                    aria-label={allyText}
                    style={{
                      backgroundImage: `url(${get(
                        marina,
                        "data.BackgroundImgUrl"
                      )})`
                    }}
                    className={classes.backgroundImageDesktop}
                  />
                </div>
                <img
                  className={classes.companyImgMobile}
                  alt={allyText}
                  src={get(marina, "data.BackgroundImgUrl")}
                />
              </>
            )}
            <Component
              {...matchProps}
              setChildrenIsLoading={setChildrenIsLoading}
            />
            <Footer />
          </div>
        )}
      />
    </>
  );
};

HomeLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  marina: PropTypes.object.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  showBackground: PropTypes.bool,
  showBorder: PropTypes.bool
};

HomeLayout.defaultProps = {
  showBackground: true,
  showBorder: false
};

HomeLayout.displayName = "HomeLayout";

export default HomeLayout;
