import React from "react";
import PropTypes from "prop-types";
import { pick } from "lodash";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import styles from "./styles.js";

const checkbox = ({
  id,
  label,
  checked,
  classes,
  className,
  value,
  required,
  submitValue,
  ...rest
}) => {
  return (
    <FormControlLabel
      classes={className || pick(classes, "label")}
      control={
        <Checkbox
          checked={checked}
          margin="normal"
          variant="outlined"
          onChange={e => {
            return submitValue({
              id: id,
              value: e.target.checked,
              required: required
            });
          }}
          value={value}
          color="primary"
        />
      }
      label={label}
      {...rest}
    />
  );
};

checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  checked: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  classes: PropTypes.object,
  className: PropTypes.object,
  required: PropTypes.bool,
  submitValue: PropTypes.func.isRequired
};

export default withStyles(styles)(checkbox);
