import Footer from "./Footer.js";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { getMarinaName } from "redux/marina/selectors.js";
import styles from "./styles.js";

const mapStateToProps = (state, props) => {
  return {
    marinaName: getMarinaName(state)
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = {};
  return { actions };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(Footer)));
