export default theme => ({
  label: {
    marginBottom: 12,
    color: "#8B999D",
    fontFamily: "Maven Pro",
    fontSize: 16,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  labelError: {
    color: "#C72323"
  }
});
