export default () => ({
  root: {
    height: 56,
    textAlign: "center",
    color: "white",
    borderRadius: 4,
    whiteSpace: "nowrap",
    fontFamily: "Maven Pro",
    fontSize: 18,
    fontWeight: 400,
    lineHeight: 1.167,
    letterSpacing: -0.4,
    textTransform: "none",
    minWidth: 140,

    "&:disabled": {
      backgroundColor: "rgba(0, 0, 0, 0.12)"
    }
  },
  primary: {
    backgroundColor: "#67AAD1",

    "&:hover": {
      backgroundColor: "#67AAD1"
    }
  },
  secondary: {
    color: "#67AAD1",
    border: "1px solid #67AAD1",

    "&:hover": {
      backgroundColor: "#FFFFFF"
    }
  }
});
