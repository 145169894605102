import React, { forwardRef } from "react";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { getNumberWith2Decimals } from "utils/helper";
import Autocomplete from "@material-ui/lab/Autocomplete";

const ResourceOfferPicker = forwardRef(
  (
    {
      classes,
      isCheckingAvailability,
      name,
      standardNightlyRate,
      onPick,
      availableResources,
      onChangeResource,
      spaceId,
      enableNightly
    },
    ref
  ) => {
    return (
      <Grid ref={ref} container direction="column" className={classes.root}>
        <Grid container>
          <Grid item>
            <Typography component="h1" className={classes.title}>
              {name}
            </Typography>
            <Typography component="h3" className={classes.price}>
              ${getNumberWith2Decimals(standardNightlyRate)}{" "}
              <span>per {enableNightly ? 'night' : 'day'} exluding taxes and add-ons</span>
            </Typography>
          </Grid>
          <Grid container className={classes.buttonColumn}>
            <Button
              className={classes.submitButton}
              onClick={onPick}
              disabled={isCheckingAvailability}
            >
              <Typography variant="button" className={classes.buttonText}>
                Book Now
              </Typography>
            </Button>
          </Grid>
        </Grid>

        {availableResources && availableResources.length > 0 && (
          <Grid item className={classes.selectContainer}>
            <Autocomplete
              id="resource-space"
              options={availableResources}
              getOptionLabel={option =>
                option ? option.CustomerFacingName : null
              }
              style={{ width: "100%" }}
              onChange={(_, item) =>
                onChangeResource(item ? item.HashId : null)
              }
              value={availableResources.find(r => r.HashId === spaceId) || null}
              renderInput={({ inputProps, ...rest }) => (
                <TextField
                  {...rest}
                  inputProps={{ ...inputProps, readOnly: true }}
                  style={{ width: "100%" }}
                  label="Select Your Space (Optional)"
                  variant="outlined"
                />
              )}
            />
          </Grid>
        )}
      </Grid>
    );
  }
);

ResourceOfferPicker.displayName = "ResourceOfferPicker";

export default ResourceOfferPicker;
