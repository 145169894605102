import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { startCase } from "lodash";
import SplashScreen from "components/Shared/SplashScreen";

const BusinessList = ({ classes, actions, isLoading, marinas, location }) => {
  useEffect(() => {
    actions.searchMarinas();
  }, [actions]);
  if (isLoading) return <SplashScreen animation="lighthouse" />;
  return (
    <Paper className={classes.root} elevation={1}>
      <Typography variant="h6" style={{ margin: "2rem 0" }}>
        We could not find any marine business with that web address.
      </Typography>
      <Typography variant="body1" style={{ margin: "2rem 0" }}>
        Please pick from one of the marine businesses below that allow online
        bookings.
      </Typography>
      {marinas.map((item, i) => (
        <div key={i} className={classes.card}>
          <div key={i} className={classes.imageBox}>
            <a href={`${window.location.origin}/${item.MarinaUrlName}`}>
              <img
                className={classes.image}
                src={item.MarinaLogoUrl}
                alt="Logo"
              />
            </a>
          </div>
          <div className={classes.text}>
            <a href={`${window.location.origin}/${item.MarinaUrlName}`}>
              {startCase(item.MarinaUrlName)}
            </a>
          </div>
        </div>
      ))}
    </Paper>
  );
};

BusinessList.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  marinas: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired
};

BusinessList.defaultProps = {};

BusinessList.displayName = "BusinessList";

export default BusinessList;
