import * as THEME from "utils/theme.js";

export default {
  typography: {
    fontFamily: "'Maven Pro', sans-serif",
    useNextVariants: true,
    h4: {
      color: "#606060"
    },
    h5: {
      color: "#606060"
    },
    h6: {
      color: "rgba(100, 100, 100, 0.90)"
    },
    subtitle1: {
      color: "rgba(100, 100, 100, 0.90)"
    },
    body1: {
      color: "rgba(100, 100, 100, 0.90)"
    }
  },
  palette: {
    primary: {
      main: `${THEME.COLOR}`
    },
    error: {
      main: `${THEME.WARNING}`
    }
  }
};
