import * as SIZE from "utils/constants";

export default theme => ({
  root: {
    width: "100%",
    borderRadius: 6,
    backgroundColor: "#FFFFFF",
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.1)",
    padding: 28,
    fontWeight: 400,
    margin: "24px 0",

    [theme.breakpoints.up(SIZE.LARGE)]: {
      padding: "64px 96px",
    }
  },
  title: {
    fontSize: 20,
    lineHeight: "30px",
    color: "#202124",

    "& strong": {
      fontWeight: "700",
    },
  },
  subtitle: {
    fontSize: 16,
    lineHeight: "26px",
    marginTop: 20,
    color: "#202124",
    marginBottom: 25,

    [theme.breakpoints.up(SIZE.SMALL)]: {
      marginBottom: 40,
    }
  },
  submitButton: {
    height: 56,
    width: "100%",
    color: "#fff",
    fontSize: 18,
    textAlign: "center",
    backgroundColor: "#67AAD1",
    borderRadius: 4,
    marginTop: "auto",

    "& span": {
      fontWeight: "normal",
      textTransform: "none",
    },

    "&:hover": {
      backgroundColor: "#67AAD1"
    },
    "&[disabled]": {
      backgroundColor: "rgba(0, 0, 0, 0.12)"
    }
  },
  submitted: {
    border: "1px solid green",
    padding: "15px",
    borderRadius: "6px",
    margin: "16px 0px",
    textAlign: "center"
  },
  submittedWithError: {
    border: "1px solid red",
    padding: "15px",
    borderRadius: "6px",
    margin: "16px 0px",
    textAlign: "center"
  },
  success: {
    color: "green"
  },
  error: {
    color: "red"
  },
  linkBack: {
    textDecoration: "none",
  }
});
