export default theme => ({
  container: {
    flex: "1",
    width: "100%"
  },
  submittedWithError: {
    border: "1px solid red",
    padding: "15px",
    borderRadius: "6px",
    margin: "16px 0px",
    textAlign: "center"
  },
  error: {
    color: "red"
  }
});
