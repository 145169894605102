import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Title from "components/Shared/Title";
import { getBoatDetails } from "utils/localStorage.js";
import { get } from "lodash";
import moment from "moment";
import { DatePicker, Autocomplete, TextField } from "components/FormFields";
import { DATE_FORMAT } from "utils/constants.js";
import shorePowerValues from "redux/offer/shorePowerValues.js";

const BoatDetails = ({ classes, actions, marina, boatDetail, filter }) => {
  useEffect(() => {
    // BEGIN - If date from localStorage, retrieve it
    getBoatDetails() &&
      (boatDetail = getBoatDetails()) && // eslint-disable-line
      actions.add(boatDetail);
    // END - If date from localStorage, retrieve it
    !get(boatDetail, "boatName") &&
      actions.add({ boatName: { value: "", required: true } });
    // Override shore power value with the one searched
    actions.add({
      shorePower: { value: get(filter, "shorePower.value", 0), required: true }
    });
    !get(boatDetail, "insuranceProvider") &&
      actions.add({
        insuranceProvider: {
          value: "",
          required: get(marina, "VesselInsuranceRequired", false)
        }
      });
    !get(boatDetail, "expirationDate") &&
      actions.add({
        expirationDate: {
          value: "",
          required: get(marina, "VesselInsuranceRequired", false)
        }
      });
  }, []);
  const handleChange = ({ id, value, required }) => {
    actions.add({ [id]: { value, required } });
  };
  return (
    <form className={classes.container} noValidate autoComplete="off">
      <Paper className={classes.boat} elevation={1}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={12} sm={6}>
            <TextField
              id="boatName"
              label="Boat Name"
              value={get(boatDetail, "boatName.value", "")}
              submitValue={e => handleChange(e)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="make"
              label="Make"
              value={get(boatDetail, "make.value", "")}
              submitValue={e => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="model"
              label="Model"
              value={get(boatDetail, "model.value", "")}
              submitValue={e => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="color"
              label="Color"
              value={get(boatDetail, "color.value", "")}
              submitValue={e => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="length"
              label="Length"
              value={get(filter, "length.value", "")}
              submitValue={e => handleChange(e)}
              required
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="beam"
              label="Beam"
              value={get(filter, "beam.value", "")}
              submitValue={e => handleChange(e)}
              required
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="draft"
              label="Draft"
              value={get(filter, "draft.value", "")}
              submitValue={e => handleChange(e)}
              required
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="shorePower"
              label="Shore Power"
              value={get(boatDetail, "shorePower.value", 0)}
              values={shorePowerValues}
              submitValue={e => handleChange(e)}
              disabled={!!get(filter, "shorePower.value", 0)}
              withNone
            />
          </Grid>
        </Grid>
      </Paper>
      <Title
        currentPage={{ url: "/boat-details", value: "Insurance Details" }}
      />
      <Paper className={classes.insurance} elevation={1}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={12} sm={6}>
            <TextField
              id="insuranceProvider"
              label="Insurance Provider"
              value={get(boatDetail, "insuranceProvider.value", "")}
              submitValue={e => handleChange(e)}
              required={get(marina, "VesselInsuranceRequired", false)}
            />
          </Grid>
          <Grid item xs={12} sm={6} style={{ alignSelf: "flex-start" }}>
            <DatePicker
              id="expirationDate"
              label="Expiration Date"
              value={get(boatDetail, "expirationDate.value")}
              submitValue={e => handleChange(e)}
              required={get(marina, "VesselInsuranceRequired", false)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="liabilityMaximun"
              label="Liability Maximum"
              value={get(boatDetail, "liabilityMaximun.value", "")}
              submitValue={e => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={6} style={{ alignSelf: "flex-start" }}>
            <DatePicker
              id="effectiveDate"
              label="Effective Date"
              value={get(boatDetail, "effectiveDate.value")}
              minDate={moment().subtract(24, "month").format(DATE_FORMAT)}
              submitValue={e => handleChange(e)}
            />
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
};

BoatDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  boatDetail: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  marina: PropTypes.object.isRequired
};

BoatDetails.displayName = "BoatDetails";

export default BoatDetails;
