import React, { useEffect, useState } from "react";
import Summary from "./Summary";
import { Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import * as ROUTES from "utils/constants.js";
import moment from "moment";
import {
  getFilestackGalleryPhotos,
  getFilestackPrimaryPhoto
} from "redux/offer/utils";
import Breadcrumb from "components/Layout/Offer/Breadcrumb";
import { isNil } from "lodash";
import { getOfferFromUrl } from "utils/helper";

import PhotoGallery from "./PhotoGallery";
import AvailabilityForm from "./AvailabilityForm";
import ResourceOfferPicker from "./ResourceOfferPicker";
import NotAvailable from "./NotAvailable";
import useDynamicForm from "hooks/useDynamicForm";

const ResourceDetail = ({
  history,
  isMarinaLoaded,
  classes,
  actions,
  setChildrenIsLoading,
  isCheckingAvailability,
  selectedOffer,
  isOfferLoaded,
  offerList,
  error,
  isLoading,
  selectedProperties,
  marinaDetails,
  filters
}) => {
  const theme = useTheme();
  const matchesCollapsing = useMediaQuery("(max-width: 800px)");
  const matchesUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const matcheDownMd = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    name,
    offerID,
    description,
    offerType,
    filestackFiles,
    earliestArrival,
    latestDeparture,
    offerTotal,
    extras,
    standardNightlyRate,
    availableResources,
    enableNightly
  } = selectedOffer;

  // TODO: Maybe move spaceId outside selected properties.
  // spaceId is not inside selectedOffer because the last one is not
  // subscribe directly to redux reducer

  const { spaceId } = selectedProperties;

  const hasCheckedAvailability = !isNil(offerTotal);
  const primaryPhoto = getFilestackPrimaryPhoto(filestackFiles);
  const photos = getFilestackGalleryPhotos(filestackFiles);
  const [notAvailable] = useState(false); // TODO: Connect to UI.
  const pickerRef = React.useRef();

  useEffect(() => {
    if (isMarinaLoaded) {
      if (!offerID) {
        const offerUrlData = getOfferFromUrl(history.location.pathname);

        if (offerUrlData) {
          setChildrenIsLoading(true);
          actions.setOfferTypeAsResource();

          // Resource's list loaded
          if (!isOfferLoaded) {
            if (!isLoading) {
              actions.searchMarinaResources(true);
            }
          } else {
            const resource = offerList.find(
              el => el.offerID === offerUrlData.offerID
            );

            // Does the resource exist?
            if (resource) {
              actions.addToSelected({
                id: resource.offerID,
                type: ROUTES.OFFER_TYPES.RESOURCE
              });
            } else {
              history.push(ROUTES.RESOURCES);
            }
          }
        } else {
          history.push(ROUTES.RESOURCES);
        }
      } else {
        setChildrenIsLoading(false);
      }
    }

    return () => {
      if (history.location.pathname === ROUTES.RESOURCES) {
        actions.resetSelectedOffer();
      }
    };
  }, [
    actions,
    setChildrenIsLoading,
    isMarinaLoaded,
    isOfferLoaded,
    offerID,
    offerList,
    history,
    isLoading
  ]);

  const dynamicForm = useDynamicForm(
    marinaDetails.CustomFields,
    filters.custom,
    "MM/DD/YY"
  );

  const handleCheckAvailability = values => {
    const { arrival, departure, ...customFilters } = values;

    const parsedFilters = dynamicForm.parseCustomFieldsValues(customFilters);

    // TODO: Remove "value" nested prop

    actions.addFilters({
      arrival: { value: arrival },
      departure: { value: departure },
      custom: parsedFilters
    });

    actions.addToSelected({
      spaceId: null
    });

    actions.checkResourceOfferAvailability(offerID);
  };

  useEffect(() => {
    if (!isCheckingAvailability && hasCheckedAvailability) {
      if (pickerRef.current) {
        pickerRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
      }
    }
  }, [hasCheckedAvailability, isCheckingAvailability]);

  useEffect(() => {
    if (
      offerID &&
      !isNil(filters.arrival.value) &&
      !isNil(filters.departure.value)
    ) {
      actions.checkResourceOfferAvailability(offerID);
    }
    // TODO: Find a way to avoid bypassing this rule
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerID]);

  const handlePickOffer = () => {
    actions.addExtra({ extras });
    actions.holdResource();
    history.push(ROUTES.EXTRAS);
  };

  const handleChangeResource = newSpaceId => {
    actions.addToSelected({
      spaceId: newSpaceId
    });
  };

  const parsedEarliestArrival = moment(earliestArrival).format("MM/DD/YYYY");
  const parsedLatestDeparture = moment(latestDeparture).format("MM/DD/YYYY");

  const showOfferPicker = hasCheckedAvailability && !error;

  if (!offerID) {
    return null;
  }

  return (
    <Grid className={classes.root}>
      <Grid container className={classes.breadcrumbsContainer}>
        <Breadcrumb
          offerType={ROUTES.OFFER_TYPES.RESOURCE}
          showOnMobile
          selectedPage={name}
        />
      </Grid>

      <Grid
        container
        justify="space-between"
        direction={matcheDownMd ? "column" : "row"}
        wrap="nowrap"
        className={classes.mainContent}
      >
        <Grid
          item
          style={{
            marginRight: matchesUpMd ? 10 : 0
          }}
        >
          <AvailabilityForm
            onSubmit={handleCheckAvailability}
            earliestArrival={parsedEarliestArrival}
            latestDeparture={parsedLatestDeparture}
            isCheckingAvailability={isCheckingAvailability}
            error={error}
            history={history}
            initialValues={{
              arrival: filters.arrival.value,
              departure: filters.departure.value,
              ...dynamicForm.initialValues
            }}
            validation={dynamicForm.validation}
            optionalFields={dynamicForm.optional}
            requiredFields={dynamicForm.required}
          />

          {error && (
            <Grid item className={classes.errorContainer}>
              {error}
            </Grid>
          )}

          {showOfferPicker && (
            <Grid className={classes.offerPickerContainer}>
              <ResourceOfferPicker
                ref={pickerRef}
                name={name}
                standardNightlyRate={standardNightlyRate}
                isCheckingAvailability={isCheckingAvailability}
                onPick={handlePickOffer}
                availableResources={availableResources}
                onChangeResource={handleChangeResource}
                enableNightly={enableNightly}
                spaceId={spaceId}
              />
            </Grid>
          )}
        </Grid>

        <Grid
          item
          style={{
            marginLeft: matchesUpMd ? 10 : 0
          }}
        >
          <Summary
            id={offerID}
            category={offerType}
            title={name}
            description={description}
            photo={primaryPhoto}
            notAvailable={
              !matchesCollapsing && notAvailable ? <NotAvailable /> : null
            }
          />
        </Grid>

        {matchesCollapsing && notAvailable && <NotAvailable />}
      </Grid>

      {photos.length > 0 && (
        <Grid item className={classes.galleryContainer}>
          <Typography variant="h3" className={classes.galleryTitle}>
            Photo Gallery
          </Typography>
          <PhotoGallery photos={photos} />
        </Grid>
      )}
    </Grid>
  );
};

export default ResourceDetail;
