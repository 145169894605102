import * as SIZE from "utils/constants";

export default theme => ({
  root: {
    color: "#2A333C",
    width: 457,
    position: "sticky",
    top: 10,

    [theme.breakpoints.down(SIZE.XSMALL)]: {
      width: "auto",
      padding: "0 10px"
    }
  },
  photo: {
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: 360,
    borderRadius: 6,
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      maxWidth: 335,
      height: 245.79
    }
  },
  subtitle: {
    color: "inherit",
    fontSize: 16,
    marginTop: 10,
    lineHeight: "18px"
  },
  title: {
    color: "#2A333C",
    fontSize: 32,
    fontWeight: "bold",
    lineHeight: "38px",
    fontFamily: "Maven Pro"
  }
});
