import { FormControl } from "@material-ui/core";
import React from "react";
import classNames from "classnames";
import { KeyboardDatePicker } from "@material-ui/pickers";
import dateIcon from "assets/icons/date.svg";
import Label from "../Label";

const DatePicker = ({
  classes,
  label,
  id,
  className,
  onChange,
  value,
  maxDate,
  minDate,
  error,
  popoverX,
  popoverY,
  format,
  required,
  rest
}) => {
  const handleChange = (_, value) =>
    onChange({
      target: {
        id,
        value
      }
    });

  return (
    <FormControl
      fullWidth
      className={classNames({
        [className]: !!className
      })}
    >
      {label && (
        <Label error={error} required={required}>
          {label}
        </Label>
      )}
      <KeyboardDatePicker
        error={error}
        autoOk
        disableToolbar
        variant="inline"
        format={format || "MM/DD/YY"}
        margin="normal"
        value={value}
        id={id}
        maxDate={maxDate}
        minDate={minDate}
        onChange={handleChange}
        keyboardIcon={<img src={dateIcon} alt="date" />}
        inputVariant="outlined"
        required={required}
        className={classNames(classes.commonInput, classes.input)}
        KeyboardButtonProps={{
          "aria-label": "change date"
        }}
        PopoverProps={{
          transformOrigin: {
            horizontal: popoverX || 120,
            vertical: popoverY || 100
          }
        }}
        {...rest}
      />
    </FormControl>
  );
};

DatePicker.displayName = "DatePicker";

export default DatePicker;
