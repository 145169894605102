import React from "react";
import { Grid, Box, useMediaQuery, useTheme } from "@material-ui/core";
import DatePicker from "components/NewSite/FormFields/DatePicker";
import Button from "components/NewSite/Button";

const FixedForm = ({
  classes,
  formik,
  earliestArrival,
  latestDeparture,
  onSubmit,
  onClickOverlay,
  onClickExpand,
  isSubmitting
}) => {
  const theme = useTheme();
  const matchesUpMd = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      wrap="nowrap"
      className={classes.root}
    >
      {!matchesUpMd && (
        <Box
          className={classes.overlay}
          onClick={e => {
            e.stopPropagation();
            onClickOverlay();
          }}
        ></Box>
      )}
      <Grid
        container
        className={classes.content}
        justify={matchesUpMd ? "space-between" : "center"}
        wrap="nowrap"
      >
        <Box display="flex" alignItems="center" flexWrap="nowrap">
          <Box
            display="flex"
            alignItems="center"
            className={classes.inputContainer}
          >
            {matchesUpMd && <label>Arrival</label>}
            <Box>
              <DatePicker
                label={!matchesUpMd && "Arrival"}
                onChange={formik.handleChange}
                value={new Date(formik.values.arrival)}
                minDate={new Date(earliestArrival)}
                maxDate={new Date(latestDeparture)}
                format="MM/DD/YY"
              />
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            className={classes.inputContainer}
          >
            {matchesUpMd && <label>Departure</label>}
            <Box>
              <DatePicker
                label={!matchesUpMd && "Departure"}
                onChange={formik.handleChange}
                value={new Date(formik.values.departure)}
                minDate={new Date(earliestArrival)}
                maxDate={new Date(latestDeparture)}
                format="MM/DD/YY"
              />
            </Box>
          </Box>
          {matchesUpMd && (
            <Box onClick={onClickExpand}>
              <span className={classes.expandText}>Expand</span>
            </Box>
          )}
        </Box>
        {matchesUpMd && (
          <Box className={classes.buttonContainer}>
            <Button
              className={classes.submitButton}
              disabled={isSubmitting}
              onClick={onSubmit}
            >
              Check Availability
            </Button>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default FixedForm;
