import * as SIZE from "utils/constants.js";

export default theme => ({
  container: { flex: "1" },
  boat: {
    padding: "60px",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      maxWidth: "100%",
      padding: "25px"
    }
  },
  insurance: {
    padding: "60px",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      maxWidth: "100%",
      padding: "25px"
    }
  }
});
