import React, { useEffect } from "react";
import { Grid, Typography, useMediaQuery } from "@material-ui/core";
import headerBackground from "assets/img/resources-header.png";
import GridItem from "components/Shared/GridItem";
import { kebabCase } from "lodash";
import { OFFER_TYPES } from "utils/constants";
import { getFilestackPrimaryPhoto } from "redux/offer/utils";
import SearchFilters from "./SearchFilters";
import classNames from "classnames";
import useDynamicForm from "hooks/useDynamicForm";
import SplashScreen from "components/Shared/SplashScreen";

const Resources = ({
  offerList,
  classes,
  actions,
  setChildrenIsLoading,
  isLoading,
  isMarinaLoaded,
  isOfferLoaded,
  history,
  isOfferSearchingAgain,
  marinaDetails,
  filters,
  error,
}) => {
  const matchesXxs = useMediaQuery("(max-width: 350px)");
  const matchesCollapsing = useMediaQuery(
    "(min-width: 960px) and (max-width: 1075px)"
  );

  useEffect(() => {
    actions.setOfferTypeAsResource();
    setChildrenIsLoading(true);
  }, [actions, setChildrenIsLoading]);

  useEffect(() => {
    if (isMarinaLoaded) {
      actions.searchMarinaResources();
    }
  }, [actions, isMarinaLoaded]);

  useEffect(() => {
    if (isOfferLoaded) {
      setChildrenIsLoading(false);
    }
  }, [isOfferLoaded, setChildrenIsLoading]);

  const dynamicForm = useDynamicForm(
    marinaDetails.CustomFields,
    filters.custom,
    "MM/DD/YY"
  );

  const handleViewDetails = (name, resource) => {
    actions.addToSelected({
      id: resource.offerID,
      type: OFFER_TYPES.RESOURCE
    });
    history.push(`/resources/${resource.offerID}-${kebabCase(name)}`);
  };

  const handleSubmitSearch = values => {
    const { arrival, departure, ...customFilters } = values;

    const parsedFilters = dynamicForm.parseCustomFieldsValues(customFilters);

    // TODO: Remove "value" nested prop

    actions.addFilters({
      arrival: { value: arrival },
      departure: { value: departure },
      custom: parsedFilters
    });

    actions.searchMarinaResources();
  };

  const renderContent = () => {
    if (offerList.length > 0) {
      return (
        <Grid
          className={classNames({
            [classes.resourcesGrid]: true,
            [classes.transparent]: isOfferSearchingAgain
          })}
          container
          justify="flex-start"
          spacing={matchesCollapsing ? 1 : 0}
        >
          {offerList.map(resource => {
            const primaryPhoto = getFilestackPrimaryPhoto(
              resource.filestackFiles
            );
            return (
              <Grid
                item
                xs={matchesXxs ? 12 : 6}
                sm={4}
                className={classes.gridItem}
                key={resource.name}
              >
                <GridItem
                  background={primaryPhoto}
                  name={resource.name}
                  onViewDetails={name => handleViewDetails(name, resource)}
                />
              </Grid>
            );
          })}
        </Grid>
      )
    }
    if (error) {
      return (
        <Grid className={classes.noAvailability}>
          There is no availability for this search. Please try again.
        </Grid>
      );
    }
    return null;
  };

  return (
    <>
      {!isLoading && (
        <Grid container alignItems="center" direction="column">
          <Grid
            container
            justify="center"
            className={classes.header}
            style={{ backgroundImage: `url(${headerBackground})` }}
          >
            <Grid item className={classes.titleContainer}>
              <Typography variant="h1" className={classes.title}>
                Resources Booking
              </Typography>
            </Grid>
          </Grid>
          <Grid className={classes.searchContainer}>
            <SearchFilters
              onSubmit={handleSubmitSearch}
              disabled={isOfferSearchingAgain}
              initialValues={{
                arrival: filters.arrival.value,
                departure: filters.departure.value,
                ...dynamicForm.initialValues
              }}
              validation={dynamicForm.validation}
              dynamicFields={dynamicForm.allFields}
            />
          </Grid>
          {isOfferSearchingAgain ? (
            <SplashScreen animation="buoy" showQuotes={false} />
          ) : (
            renderContent()
          )}
        </Grid>
      )}
    </>
  );
};

Resources.displayName = "Resources";

export default Resources;

// /offerList/${resource.OfferId}
