import { country } from "./country.js";
import { isEmpty } from "lodash";
import { COUNTRIES_BY_CALLING_CODE, COUNTRIES_BY_ISO_2, COUNTRIES_BY_ISO_3, PAGES } from "./constants.js";
import { CUSTOM_FIELDS } from "utils/constants";

export const capitalizeFirstLetter = string => {
  const firstLetter = string.charAt(0).toUpperCase();
  const restOfString = string.slice(1);

  return firstLetter + restOfString;
}

export const getName = pathname => {
  let name = pathname.match(/\/.*\//g);
  name = (name && name[0].slice(1).slice(0, -1)) || pathname.slice(1);

  if (name.includes("/")) {
    name = name.split("/")[0];
  }

  return name;
};

export const getNumberWith2Decimals = number => {
  return Math.round(parseFloat(number, 10)) !== parseFloat(number, 10)
    ? parseFloat(number, 10).toFixed(2)
    : number;
};

export const getCountries = () => {
  return Object.keys(country);
};

export const getProvinces = countryName => {
  return !isEmpty(country[countryName].provinces)
    ? country[countryName].provinces
    : [];
};

export const getCountryByCode = ({ callingCode, iso2, iso3 }) => {
  if (callingCode && (callingCode in COUNTRIES_BY_CALLING_CODE)) {
    return COUNTRIES_BY_CALLING_CODE[callingCode];
  }

  if (iso2 && (iso2 in COUNTRIES_BY_ISO_2)) {
    return COUNTRIES_BY_ISO_2[iso2];
  }

  if (iso3 && (iso3 in COUNTRIES_BY_ISO_3)) {
    return COUNTRIES_BY_ISO_3[iso3];
  }

  return null;
}

export const removePhoneCountryCode = (phoneNumber, countryCode) => {
  if (isEmpty(phoneNumber)) {
    return "";
  }

  return phoneNumber.replace(`+${countryCode}`, '');
} 

export const usingStripe = provider => {
  return provider !== "Authorize.net";
};

export const getOfferFromUrl = pathname => {
  const regexp = /\/resources\/(?<OfferID>[^-]+)-.+$/;
  const result = regexp.exec(pathname);

  if (!result) {
    console.warn("OfferId's pattern not found");

    return null;
  }

  return {
    offerID: result.groups.OfferID,
    offerSlug: "" // TODO: Parse resource name to render breadcrumbs correctly
  };
};

export const getPages = (offerType, dynamicPage) => {
  const pages = [...(PAGES[offerType] || [])];

  if (dynamicPage) {
    const { position, value, url = "", nextUrl = "" } = dynamicPage;

    const newPage = {
      value,
      url,
      nextUrl
    };

    pages.splice(position, 0, newPage);
  }

  return pages;
};

export const getDynamicFormValue = (formik, field) => {
  let initialValue = field.initialValue;

  if(formik.dirty && [CUSTOM_FIELDS.NUMBER, CUSTOM_FIELDS.TEXT].includes(field.type)) {
    initialValue = "";
  }

  const dateValue = formik.values[field.id]
    ? new Date(formik.values[field.id])
    : initialValue;
  let commonValue;

  commonValue = formik.values[field.id] || initialValue;

  const value = field.type === CUSTOM_FIELDS.DATE ? dateValue : commonValue;

  return value;
};
