import Header from "./Header.js";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { getMarina, getMarinaIsLoading } from "redux/marina/selectors.js";
import styles from "./styles.js";

const mapStateToProps = (state, props) => {
  return {
    marina: getMarina(state),
    isLoading: getMarinaIsLoading(state)
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = {};
  return { actions };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Header));
