import * as SIZE from "utils/constants.js";

export default theme => ({
  root: {
    flexGrow: 1,
    minHeight: "100vh",
    [theme.breakpoints.down(SIZE.SMALL)]: {
      backgroundColor: "#F5F5F5"
    }
  },
  background: {
    margin: "0 7%",
    padding: "0 20% 5% 20%",
    minHeight: "75vh",
    borderTop: `5px solid ${theme.palette.primary.main}`,
    backgroundColor: "#F3F5F6",
    minWidth: "600px",
    [theme.breakpoints.down(SIZE.MEDIUM)]: {
      minWidth: "500px",
      padding: "0 10% 5% 10%"
    },
    [theme.breakpoints.down(SIZE.SMALL)]: {
      minWidth: "inherit",
      margin: "0",
      marginBottom: "7%",
      padding: "0 7%"
    }
  }
});
