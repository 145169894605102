//Action types
const ADD = "ADD_TO_SEARCH";
const RESET_SEARCH = "RESET_SEARCH";

//Functions

//Action creators
export function add(payload) {
  return {
    type: ADD,
    payload
  };
}

export function resetSearchStore() {
  return {
    type: RESET_SEARCH
  };
}

//Reducers
const initialState = {
  arrival: {
    required: true
  },
  departure: {
    required: true
  },
  custom: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD:
      return { ...state, ...action.payload };
    case RESET_SEARCH:
      return initialState;
    case "RESET":
      return initialState;
    default:
      return state;
  }
};

// export default search;
