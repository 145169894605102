import * as SIZE from "utils/constants.js";

export default theme => ({
  root: {
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      display: "none"
    }
  },
  selected: {
    position: "relative",

    "& p": {
      color: "#67AAD1"
    },

    "&::after": {
      position: "absolute",
      bottom: -3,
      content: '""',
      backgroundColor: "#67AAD1",
      width: "100%",
      height: 2
    }
  },
  link: { textDecoration: "none", color: "black" },
  showOnMobile: {
    display: "block !important"
  }
});
