import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import LocalOfferIcon from "@material-ui/icons/LocalOfferOutlined";
import AddIcon from "@material-ui/icons/AddOutlined";
import PermIdentityIcon from "@material-ui/icons/PermIdentityOutlined";
import WavesIcon from "@material-ui/icons/WavesOutlined";
import PaymentIcon from "@material-ui/icons/PaymentOutlined";
import DoneIcon from "@material-ui/icons/DoneOutlined";
import * as ROUTES from "utils/constants.js";

const Title = ({ classes, currentPage, customIcon: CustomIcon, ...rest }) => {
  const selectIcon = () => {
    switch (currentPage.icon || currentPage.url) {
      case ROUTES.OFFERS:
        return <LocalOfferIcon className={classes.icon} />;
      case ROUTES.EXTRAS:
        return <AddIcon className={classes.icon} />;
      case ROUTES.CONTACT_DETAILS:
        return <PermIdentityIcon className={classes.icon} />;
      case ROUTES.BOAT_DETAILS:
        return <WavesIcon className={classes.icon} />;
      case ROUTES.PAYMENT:
        return <PaymentIcon className={classes.icon} />;
      case ROUTES.BOOKING_COMPLETE:
        return <DoneIcon className={classes.icon} />;
      default:
        return;
    }
  };
  return (
    <Typography className={classes.title} variant="h5">
      {CustomIcon ? <CustomIcon className={classes.icon} /> : selectIcon()}
      {currentPage.value}
    </Typography>
  );
};

Title.propTypes = {
  classes: PropTypes.object.isRequired,
  currentPage: PropTypes.shape({
    icon: PropTypes.string,
    url: PropTypes.string,
    value: PropTypes.string.isRequired
  }).isRequired
};

Title.displayName = "Title";

export default Title;
