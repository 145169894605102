import BoatDetails from "./boatDetails.js";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { add } from "redux/boat";
import { withStyles } from "@material-ui/core/styles";
import { getMarinaDetails } from "redux/marina/selectors.js";
import { getFilters } from "redux/search/selectors.js";
import { getBoatDetails } from "redux/boat/selectors.js";
import styles from "./styles.js";

const mapStateToProps = (state, props) => {
  return {
    marina: getMarinaDetails(state),
    filter: getFilters(state),
    boatDetail: getBoatDetails(state)
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = {
    add: bindActionCreators(add, dispatch)
  };
  return { actions };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BoatDetails));
