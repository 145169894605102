export default (theme) => ({
	textField: { width: "100%", margin: 0 },
	formControl: { width: "100%" },
	label: { fontSize: "1rem" },
	phoneInput: {
		"& .react-phone-number-input__icon": {
			border: "none",
		},
	},
});
