import React, { useState, useRef } from "react";
import { Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import moment from "moment";

import * as ROUTES from "utils/constants";
import { useFormik } from "formik";
import * as yup from "yup";
import { isArray, isEmpty } from "lodash";

import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import classNames from "classnames";
import useOnScreen from "hooks/useOnScreen";
import FixedForm from "./FixedForm";
import Button from "components/NewSite/Button";
import Arrival from "components/NewSite/FormFields/Arrival";
import DatePicker from "components/NewSite/FormFields/DatePicker";

import { getDynamicFormValue } from "utils/helper";

const AvailabilityForm = ({
  classes,
  onSubmit,
  earliestArrival,
  latestDeparture,
  isCheckingAvailability,
  history,
  initialValues,
  validation,
  optionalFields,
  requiredFields
}) => {
  const theme = useTheme();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesDownXs = useMediaQuery(theme.breakpoints.down("xs"));

  const isSomeOptionalFieldFilled = (optionalFields || []).some(field => {
    if (isArray(field.initialValue)) {
      return !isEmpty(field.initialValue);
    }
    return !!field.initialValue;
  });

  const [expanded, setExpanded] = useState(isSomeOptionalFieldFilled);

  const formRef = useRef(null);

  const isFormVisible = useOnScreen(formRef);

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      arrival: initialValues.arrival ? initialValues.arrival : earliestArrival,
      departure: initialValues.departure
        ? initialValues.departure
        : moment(earliestArrival, "MM/DD/YYYY")
            .add(1, "days")
            .format("MM/DD/YY")
    },
    validationSchema: yup.object().shape({
      ...validation,
      arrival: yup.date().required(),
      departure: yup.date().required()
    }),
    onSubmit
  });

  const handleBackToResources = () => history.push(ROUTES.RESOURCES);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const scrollToForm = () => {
    formRef.current.scrollIntoView({
      behavior: "smooth"
    });
  };

  const handleSubmitFixedForm = async () => {
    formik.handleSubmit();

    // TODO: Fix this workaround to detect errors in the form when submiting from fixed navbar

    const errors = await formik.validateForm();

    const hasError = Object.keys(errors).length > 0;

    if (hasError) {
      scrollToForm();
    }
  };

  const errorKeys = Object.keys(formik.errors);

  return (
    <>
      {!isFormVisible && (
        <FixedForm
          earliestArrival={earliestArrival}
          latestDeparture={latestDeparture}
          formik={formik}
          onSubmit={handleSubmitFixedForm}
          onClickOverlay={scrollToForm}
          onClickExpand={scrollToForm}
          isSubmitting={isCheckingAvailability}
        />
      )}

      <Grid ref={formRef} container direction="column" className={classes.root}>
        <Typography variant="h6" className={classes.title}>
          Select Dates
        </Typography>
        <Grid container className={classes.inputsContainer}>
          <Grid item xs={12} sm={6} className={classes.input}>
            <Arrival
              id="arrival"
              onChange={formik.handleChange}
              departure={new Date(earliestArrival)}
              onArrivalGreaterThanDeparture={newArrival =>
                formik.setFieldValue(
                  "departure",
                  moment(newArrival, "MM/DD/YY")
                    .add(1, "day")
                    .format("MM/DD/YY")
                )
              }
              value={new Date(formik.values.arrival)}
              minDate={new Date(earliestArrival)}
              maxDate={new Date(latestDeparture)}
              required
              error={!!formik.errors.arrival}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.input}>
            <DatePicker
              id="departure"
              label="End"
              onChange={formik.handleChange}
              minDate={new Date(formik.values.arrival)}
              value={new Date(formik.values.departure)}
              maxDate={new Date(latestDeparture)}
              required
              error={!!formik.errors.departure}
            />
          </Grid>
          {requiredFields.map(field => {
            const hasError =
              errorKeys[0] === field.id && formik.submitCount > 0;
            const value = getDynamicFormValue(formik, field);

            return (
              <Grid
                item
                xs={12}
                sm={6}
                key={field.id}
                className={classes.input}
              >
                {React.createElement(field.component, {
                  ...field.props,
                  value,
                  onChange: formik.handleChange,
                  error: hasError
                })}
              </Grid>
            );
          })}
        </Grid>
        <Grid>
          {optionalFields.length > 0 && (
            <>
              <Grid item className={classes.expandOptionsContainer}>
                <div>
                  <Typography component="span" className={classes.expandText}>
                    Additional Options
                  </Typography>
                  <IconButton
                    className={classNames(classes.expand, {
                      [classes.expandOpen]: expanded
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                </div>
              </Grid>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Grid container className={classes.inputsContainer}>
                  {optionalFields.map(field => {
                    const hasError =
                      errorKeys[0] === field.id && formik.submitCount > 0;
                    const value = getDynamicFormValue(formik, field);

                    return (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        key={field.id}
                        className={classes.input}
                      >
                        {React.createElement(field.component, {
                          ...field.props,
                          value,
                          onChange: formik.handleChange,
                          error: hasError
                        })}
                      </Grid>
                    );
                  })}
                </Grid>
              </Collapse>
            </>
          )}
          <Grid
            container
            direction={matchesDownXs ? "column" : "row"}
            justify="center"
            alignItems="center"
            className={classes.travelInfo}
          >
            {!matchesDownXs && (
              <Typography variant="body1" className={classes.travelInfoText}>
                Earliest Arrival {earliestArrival} | Latest Departure{" "}
                {latestDeparture}
              </Typography>
            )}
            {matchesDownXs && (
              <>
                <Typography variant="body1" className={classes.travelInfoText}>
                  Earliest Arrival {earliestArrival}
                </Typography>
                <Typography variant="body1" className={classes.travelInfoText}>
                  Latest Departure {latestDeparture}
                </Typography>
              </>
            )}
          </Grid>
          <Grid
            direction={matchesDownSm ? "column" : "row"}
            container
            alignItems="center"
            wrap="nowrap"
          >
            <Button
              className={classes.submitButton}
              onClick={formik.handleSubmit}
              disabled={isCheckingAvailability}
            >
              <Typography variant="button" className={classes.buttonText}>
                Check Availability
              </Typography>
            </Button>
            <Typography variant="body1" className={classes.helperText}>
              Fields marked with (*) are mandatory.
            </Typography>
          </Grid>

          <Typography
            component="div"
            className={classes.backToResources}
            onClick={handleBackToResources}
          >
            Back to All Resource Offers
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

AvailabilityForm.displayName = "AvailabilityForm";

export default AvailabilityForm;
