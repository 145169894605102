import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import { DatePicker } from "@material-ui/pickers";
import { DATE_FORMAT } from "utils/constants.js";
import styles from "./styles.js";

const datePicker = ({
  id,
  name,
  label,
  value,
  classes,
  minDate,
  required,
  submitValue,
  ...rest
}) => {
  return (
    <DatePicker
      id={id}
      key={id}
      name={name || id}
      value={value ? new Date(value) : null}
      label={label || name || id}
      inputVariant="outlined"
      className={classes.textField}
      autoOk
      showTodayButton
      onChange={v => {
        return submitValue({
          id: id,
          value: moment(v).format(DATE_FORMAT),
          required: required
        });
      }}
      format={DATE_FORMAT}
      required={required}
      error={required && !value}
      minDate={new Date(minDate)}
      {...rest}
    />
  );
};

datePicker.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  classes: PropTypes.object,
  className: PropTypes.object,
  minDate: PropTypes.string,
  required: PropTypes.bool,
  submitValue: PropTypes.func.isRequired
};

datePicker.defaultProps = {
  minDate: moment().format(DATE_FORMAT)
};

datePicker.displayName = "datePicker";

export default withStyles(styles)(datePicker);
