import MapDialog from "./MapDialog.js";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import styles from "./styles.js";
import { connect } from "react-redux";
import { getMarinaName } from "redux/marina/selectors.js";
import { getOffer } from "redux/offer/selectors.js";

const mapStateToProps = (state, props) => {
  return {
    marinaName: getMarinaName(state),
    offer: getOffer(state)
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(withRouter(MapDialog))
);
