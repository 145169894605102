// TODO: clean up actions and props

import ResourceDetail from "./ResourceDetail";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import styles from "./styles";
import { bindActionCreators } from "redux";
import { add as addFilters } from "redux/search";
import {
  checkResourceOfferAvailability,
  holdResource,
  searchMarinaResources,
  setOfferTypeAsResource,
  add,
  resetSelectedOffer,
  addToSelected
} from "redux/offer/index";
import { getMarinaIsLoaded, getMarinaDetails } from "redux/marina/selectors";
import { addExtra } from "redux/extra/index";
import {
  getSelectedOffer,
  getIsCheckingAvailability,
  getOfferLoaded,
  getOfferList,
  getOfferError,
  getOfferIsLoading,
  getSelectedProperties
} from "redux/offer/selectors";

import { getFilters } from "redux/search/selectors";

const mapDispatchToProps = dispatch => {
  const actions = {
    setOfferTypeAsResource: bindActionCreators(
      setOfferTypeAsResource,
      dispatch
    ),
    searchMarinaResources: bindActionCreators(searchMarinaResources, dispatch),
    checkResourceOfferAvailability: bindActionCreators(
      checkResourceOfferAvailability,
      dispatch
    ),
    addExtra: bindActionCreators(addExtra, dispatch),
    holdResource: bindActionCreators(holdResource, dispatch),
    add: bindActionCreators(add, dispatch),
    resetSelectedOffer: bindActionCreators(resetSelectedOffer, dispatch),
    addToSelected: bindActionCreators(addToSelected, dispatch),
    addFilters: bindActionCreators(addFilters, dispatch)
  };
  return { actions };
};

const mapStateToProps = state => {
  return {
    isCheckingAvailability: getIsCheckingAvailability(state),
    isMarinaLoaded: getMarinaIsLoaded(state),
    selectedOffer: getSelectedOffer(state),
    selectedProperties: getSelectedProperties(state),
    isOfferLoaded: getOfferLoaded(state),
    offerList: getOfferList(state),
    error: getOfferError(state),
    isLoading: getOfferIsLoading(state),
    marinaDetails: getMarinaDetails(state),
    filters: getFilters(state)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ResourceDetail));
