import * as SIZE from "utils/constants.js";

export default theme => ({
  container: {
    flex: "1",
    marginBottom: "25px"
  },
  paper: {
    padding: "60px",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      height: "95%",
      maxWidth: "100%",
      padding: "25px"
    }
  },
  grid: {
    padding: "16px"
  },
  button: {
    width: "100%",
    fontSize: "14px",
    padding: "15px",
    marginBottom: "0.35em"
  },
  submitted: {
    border: "1px solid green",
    padding: "15px",
    borderRadius: "6px",
    margin: "16px 0px",
    textAlign: "center"
  },
  submittedWithError: {
    border: "1px solid red",
    padding: "15px",
    borderRadius: "6px",
    margin: "16px 0px",
    textAlign: "center"
  },
  success: {
    color: "green"
  },
  error: {
    color: "red"
  }
});
