import { get, isEmpty } from "lodash";

export const getOffer = state => get(state, "offer", {});
export const getOfferIsLoading = state => get(state, "offer.loading");
export const getOfferLoaded = state => get(state, "offer.loaded");
export const getOfferHolded = state => get(state, "offer.holded");
export const getOfferError = state => get(state, "offer.error");
export const getOfferPostLogSetId = state =>
  get(state, "offer.offerPostLogSetId");
export const getOfferList = state => get(state, "offer.list", {});
export const getSelectedOffer = state => {
  let selectedObj = {};
  const offers = getOfferList(state);
  const selected = get(state, "offer.selected", {});
  const hold = get(state, "offer.hold", {});
  if (!isEmpty(selected)) {
    selectedObj = offers.find(i => i.offerID === selected.id);
    selectedObj.id = get(selected, "id", "");
  }
  if (!isEmpty(hold)) {
    selectedObj.offerHoldID = get(hold, "offerHoldID", "");
  }

  selectedObj.type = selected.type;

  return selectedObj;
};
export const getSelectedProperties = state => get(state, "offer.selected", {});
export const getOfferType = state => get(state, "offer.type", "");
export const getIsCheckingAvailability = state =>
  get(state, "offer.isCheckingAvailability");
