import Home from "./home.js";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { resetStore } from "redux/index.js";
import { withStyles } from "@material-ui/core/styles";
import { getMarina } from "redux/marina/selectors.js";
import styles from "./styles.js";

const mapStateToProps = (state, props) => {
  return {
    marina: getMarina(state)
  };
};
const mapDispatchToProps = (dispatch, props) => {
  const actions = {
    resetStore: bindActionCreators(resetStore, dispatch)
  };
  return { actions };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Home));
