import React, { Fragment } from "react";
import { Router, Switch, Redirect } from "react-router-dom";
import history from "history.js";

import BlankLayout from "components/Layout/Blank";
import HomeLayout from "components/Layout/Home";
import OfferLayout from "components/Layout/Offer";

import Home from "components/Home";
import Search from "components/Search";
import Offer from "components/Offer";
import Extra from "components/Extra";
import ContactDetails from "components/ContactDetails";
import BoatDetails from "components/BoatDetails";
import Payment from "components/Payment";
import BookingComplete from "components/BookingComplete";
import Resources from "components/Resources";
import BusinessList from "components/BusinessList";
import ScrollToTop from "components/App/ScrollToTop.js";

import * as ROUTES from "utils/constants.js";
import ResourceDetail from "components/ResourceDetail";

const Routes = ({ companyName }) => {
  return (
    <Router history={history(companyName)}>
      <Fragment>
        <ScrollToTop />
        <Switch>
          <Redirect exact from="/" to={ROUTES.HOME} />
          <HomeLayout track exact path={ROUTES.HOME} component={Home} />
          <HomeLayout track exact path={ROUTES.SEARCH} component={Search} />
          <OfferLayout track exact path={ROUTES.OFFERS} component={Offer} />
          <OfferLayout track exact path={ROUTES.EXTRAS} component={Extra} />
          <OfferLayout
            track
            exact
            path={ROUTES.CONTACT_DETAILS}
            component={ContactDetails}
          />
          <OfferLayout
            track
            exact
            path={ROUTES.BOAT_DETAILS}
            component={BoatDetails}
          />
          <OfferLayout track exact path={ROUTES.PAYMENT} component={Payment} />
          <BlankLayout
            track
            exact
            path={ROUTES.BOOKING_COMPLETE}
            component={BookingComplete}
          />
          <BlankLayout path="/business-list" component={BusinessList} />
          <HomeLayout
            showBorder
            showBackground={false}
            path={`${ROUTES.RESOURCES}/:id`}
            component={ResourceDetail}
            styles={{
              backgroundColor: "white"
            }}
          />
          <HomeLayout
            showBackground={false}
            path={ROUTES.RESOURCES}
            component={Resources}
            styles={{
              backgroundColor: "white"
            }}
          />
          <Redirect from="*" to={ROUTES.NO_RESULTS} />
        </Switch>
      </Fragment>
    </Router>
  );
};

export default Routes;
