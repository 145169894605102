export default theme => ({
  title: {
    backgroundColor: "white",
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
    borderRadius: "5px",
    padding: "10px",
    marginBottom: "1.2em",
    textAlign: "left"
  }
});
