import axios from "axios";
import * as API from "redux/api";
import { parseCustomFields } from "./utils";

//Action types
const ADD = "ADD_TO_MARINA";

//Functions
export const searchMarina = marinaId => {
  return async dispatch => {
    try {
      dispatch(add({ loading: true }));
      axios
        .post(API.marina, {
          UrlName: marinaId
        })
        .then(response => {
          dispatch(
            add({
              data: {
                ...response.data,
                CustomFields: response.data.CustomFields ? parseCustomFields(response.data.CustomFields ) : [],
              }
            })
          );
          dispatch(add({ loading: false }));
          dispatch(add({ loaded: true }));
        })
        .catch(error => {})
        .finally(() => {
          dispatch(add({ loading: false }));
          dispatch(add({ loaded: true }));
        });
    } catch (error) {
      dispatch(add({ loading: false }));
    }
  };
};

export const searchMarinas = () => {
  return async dispatch => {
    try {
      dispatch(add({ loading: true }));
      axios
        .get(API.marinas, {})
        .then(response => {
          dispatch(add({ list: response.data }));
          dispatch(add({ loading: false }));
          dispatch(add({ loaded: true }));
        })
        .catch(error => {})
        .finally(() => {
          dispatch(add({ loading: false }));
          dispatch(add({ loaded: true }));
        });
    } catch (error) {
      dispatch(add({ loading: false }));
    }
  };
};

//Action creators
export function add(payload) {
  return {
    type: ADD,
    payload
  };
}

//Reducers
const initialState = {
  data: {},
  list: [],
  loading: false,
  loaded: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
