import valid from "card-validator";
import { replace } from "lodash";

export const parseExpiryDate = expiryDate => {
  expiryDate = replace(expiryDate, "-", "/");
  let month = valid.expirationDate(expiryDate).month;
  month = month.length === 1 ? `0${month}` : month;
  let year = valid.expirationDate(expiryDate).year;
  year = year.length === 4 ? year.slice(2) : year;
  return `${month}/${year}`;
};
