import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { isEmpty } from "lodash";
import styles from "./styles.js";

const textField = ({
  id,
  label,
  placeHolder,
  classes,
  className,
  value,
  required,
  validate,
  submitValue,
  ...rest
}) => {
  return (
    <TextField
      id={id}
      label={label || id}
      placeholder={placeHolder || label || id}
      className={className || classes.textField}
      margin="normal"
      variant="outlined"
      value={value}
      required={required}
      onChange={e => {
        return submitValue({
          id: id,
          value: e.target.value,
          isValid:
            typeof validate === "function"
              ? validate(e.target.value)
              : required && !isEmpty(e.target.value),
          required: required
        });
      }}
      error={
        typeof validate === "function"
          ? !validate(value)
          : required && isEmpty(value)
      }
      {...rest}
    />
  );
};

textField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeHolder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  classes: PropTypes.object,
  className: PropTypes.object,
  required: PropTypes.bool,
  submitValue: PropTypes.func.isRequired,
  validate: PropTypes.func
};

export default withStyles(styles)(textField);
