import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const AlertDialog = ({ open, handleCloseOk, handleCloseCancel }) => {
  return (
    <Dialog
      open={open}
      onClose={handleCloseCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Are you sure you want to leave?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          By clicking YES you will lose your current reservation.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseCancel} color="primary">
          No
        </Button>
        <Button onClick={handleCloseOk} color="primary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AlertDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseOk: PropTypes.func.isRequired,
  handleCloseCancel: PropTypes.func.isRequired
};

AlertDialog.displayName = "AlertDialog";

export default AlertDialog;
