import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import Header from "components/Layout/Header";
import Footer from "components/Layout/Footer";
import Title from "components/Shared/Title";
import { getPages } from "utils/helper";

const BlankLayout = ({ component: Component, classes, offerType, ...rest }) => {
  const currentPage = getPages(offerType).find(
    page => page.url === rest.location.pathname
  );
  const defaultCurrent = {
    url: "businessList",
    value: "Molo Online Bookings"
  };
  return (
    <Route
      {...rest}
      render={matchProps => (
        <div className={classes.root}>
          <Header />
          <div className={classes.background}>
            <Title currentPage={currentPage || defaultCurrent} />
            <Component {...matchProps} />
          </div>
          <Footer />
        </div>
      )}
    />
  );
};

BlankLayout.propTypes = {
  classes: PropTypes.object.isRequired
};

BlankLayout.displayName = "BlankLayout";

export default BlankLayout;
