import Home from "./Home.js";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  getMarina,
  getMarinaIsLoading,
  getMarinaIsLoaded
} from "redux/marina/selectors.js";
import styles from "./styles.js";
import { getOfferType } from "redux/offer/selectors.js";

const mapStateToProps = (state, props) => {
  return {
    marina: getMarina(state),
    isLoading: getMarinaIsLoading(state),
    isLoaded: getMarinaIsLoaded(state),
    offerType: getOfferType(state)
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = {};
  return { actions };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Home));
