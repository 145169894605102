import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import styles from "./styles.js";

const SelectOption = ({
  id,
  label,
  classes,
  className,
  value,
  values,
  withNone,
  required,
  submitValue,
  ...rest
}) => {
  const [labelWidth, setLabelWidth] = useState(0);
  let [labelRef] = useState();
  useEffect(() => {
    setLabelWidth(ReactDOM.findDOMNode(labelRef).offsetWidth);
  }, [labelRef, value]);
  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      error={required && !value}
    >
      <InputLabel
        ref={ref => {
          labelRef = ref;
        }}
        htmlFor={`outlined-${id}-simple`}
      >
        {label || id}
      </InputLabel>
      <Select
        required={required}
        error={required && !value}
        value={value || ""}
        onChange={e =>
          submitValue({
            id: id,
            value: e.target.value,
            required: required
          })
        }
        inputProps={{
          name: id,
          id: id
        }}
        input={<OutlinedInput labelWidth={labelWidth} name={id} id={id} />}
        className={className || classes.textField}
        {...rest}
      >
        {withNone && (
          <MenuItem value={0}>
            <em>None</em>
          </MenuItem>
        )}
        {values &&
          values.map((i, index) => (
            <MenuItem value={i.id ? parseInt(i.id, 10) : i} key={index}>
              {i.value || i}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

SelectOption.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  values: PropTypes.array.isRequired,
  required: PropTypes.bool,
  withNone: PropTypes.bool,
  classes: PropTypes.object,
  className: PropTypes.object,
  submitValue: PropTypes.func.isRequired
};

SelectOption.defaultProps = {
  withNone: false
};

export default withStyles(styles)(SelectOption);
