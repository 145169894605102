import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "redux/store.js";
import App from "components/App";
import "assets/css/clear.css";
import smartlookClient from "smartlook-client";

if (window["runtime"].smartlookApiKey) {
  smartlookClient.init(window["runtime"].smartlookApiKey);

  smartlookClient.record({ 
    emails: true,
    forms: true,
    numbers: true,
    ips: true,
    api: true,
  })
}

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
