import * as SIZE from "utils/constants.js";

export default theme => ({
  root: {
    padding: "60px",
    textAlign: "center",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      maxWidth: "100%",
      padding: "25px"
    }
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    fontSize: "14px",
    padding: 0,
    margin: "1%",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      margin: "3%"
    }
  },
  link: {
    width: "200px",
    padding: "15px",
    color: "inherit",
    textDecoration: "none",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      width: "100%"
    }
  }
});
