import * as SIZE from "utils/constants.js";

export default theme => ({
  root: {
    padding: "20px 60px",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      maxWidth: "100%",
      padding: "25px"
    }
  },
  card: {
    display: "flex",
    alignItems: "center",
    marginBottom: "40px",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      flexDirection: "column"
    }
  },
  imageBox: {
    flex: 0.4
  },
  image: {
    display: "block",
    maxWidth: "200px",
    maxHeight: "95px",
    margin: "0 auto"
  },
  text: {
    flex: 0.6,
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      paddingTop: "10px"
    }
  }
});
