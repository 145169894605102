import * as SIZE from "utils/constants";

export default theme => ({
  root: {
    maxWidth: 638,
    fontWeight: "normal",
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.1)",
    borderRadius: 6,
    backgroundColor: "#FFFFFF",
    padding: 48,
    boxSizing: "border-box",

    [theme.breakpoints.down(SIZE.SMALL)]: {
      margin: "0 auto",
      padding: 20,
      maxWidth: "none"
    }
  },
  title: {
    color: "#202124",
    fontFamily: "Maven Pro",
    fontSize: 18,
    marginBottom: 32,
    fontWeight: "inherit",

    [theme.breakpoints.down(SIZE.SMALL)]: {
      marginBottom: 20
    }
  },
  travelInfo: {
    background: "rgba(103,170,209,0.1)",
    borderRadius: 6,
    padding: "9px 16px",
    boxSizing: "border-box",
    minHeight: 33,
    width: "100%",
    marginBottom: 20,
    marginTop: 12
  },
  travelInfoText: {
    lineHeight: 1,
    color: "#2A333C",
    fontFamily: "Maven Pro",
    fontSize: 14,
    fontWeight: "normal"
  },
  submitButton: {
    height: 56,
    width: 256,
    fontSize: 18,
    marginRight: 30,

    [theme.breakpoints.down(SIZE.SMALL)]: {
      width: "100%",
      marginRight: 0
    }
  },
  buttonText: {
    textTransform: "none",
    fontSize: 18
  },
  backToResources: {
    textAlign: "center",
    fontSize: 14,
    color: "#67AAD1",
    marginTop: 20,
    cursor: "pointer"
  },
  inputsContainer: {
    margin: "0 -15px",
    width: "calc(100% + 30px)",

    [theme.breakpoints.down(SIZE.SMALL)]: {
      margin: "0 -12.5px",
      width: "calc(100% + 25px)"
    }
  },
  input: {
    padding: "0 15px",
    marginBottom: 20,

    [theme.breakpoints.down(SIZE.SMALL)]: {
      padding: "0 12.5px"
    }
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    color: "#67AAD1",
    padding: 0,
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  expandOptionsContainer: {
    marginBottom: 20
  },
  expandText: {
    color: "#67AAD1",
    fontSize: 16,
    textDecoration: "underline",
    marginRight: 2
  },
  helperText: {
    fontSize: 14,
    color: "#2A333C",
    [theme.breakpoints.down(SIZE.SMALL)]: {
      fontSize: 16,
      marginTop: 20
    }
  }
});
