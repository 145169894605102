import React from "react";
import classNames from "classnames";

const Label = ({ children, required, error, classes, ...rest }) => {
  return (
    <label
      className={classNames(classes.label, { [classes.labelError]: error })}
    >
      {children}
      {required && "*"}
    </label>
  );
};

export default Label;
