import { combineReducers } from "redux";
import marinaReducer from "redux/marina/index.js";
import searchReducer from "redux/search/index.js";
import offerReducer from "redux/offer/index.js";
import extraReducer from "redux/extra/index.js";
import contactReducer from "redux/contact/index.js";
import boatReducer from "redux/boat/index.js";
import paymentReducer from "redux/payment/index.js";
import emailReducer from "redux/email/index.js";

export default combineReducers({
  marina: marinaReducer,
  search: searchReducer,
  offer: offerReducer,
  extra: extraReducer,
  contact: contactReducer,
  boat: boatReducer,
  payment: paymentReducer,
  email: emailReducer,
});

//Global action to reset the store
export function resetStore() {
  return {
    type: "RESET",
  };
}
