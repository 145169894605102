import { Grid, Typography } from "@material-ui/core";
import React from "react";
import ReactMarkdown from "react-markdown";

const Summary = ({
  title,
  category,
  description,
  photo,
  classes,
  notAvailable
}) => {
  return (
    <Grid container direction="column" className={classes.root}>
      {title && (
        <Typography variant="h3" className={classes.title}>
          {title}
        </Typography>
      )}
      {category && (
        <Typography variant="subtitle1" className={classes.subtitle}>
          {category}
        </Typography>
      )}
      {description && (
        <Grid className={classes.description}>
          <ReactMarkdown source={description} escapeHtml={false} />
        </Grid>
      )}
      {photo && (
        <Grid
          className={classes.photo}
          style={{ backgroundImage: `url(${photo})` }}
        />
      )}
      {notAvailable}
    </Grid>
  );
};

Summary.displayName = "Summary";

export default Summary;
