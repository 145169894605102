import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { random, isEmpty } from "lodash";
import Lottie from "react-lottie";
import lighthouse from "./lotties/lighthouse.json";
import buoy from "./lotties/buoy.json";
import { quotes } from "./quotes.js";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

const SplashScreen = ({ classes, absolute, animation, showQuotes }) => {
  const cx = classNames.bind(classes);
  let animationData = random(0, 1) === 0 ? buoy : lighthouse;
  switch (animation) {
    case "buoy":
      animationData = buoy;
      break;
    case "lighthouse":
      animationData = lighthouse;
      break;
    default:
      animationData = "";
      break;
  }
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  return (
    <div className={cx("splash", { absolute })}>
      <span>
        {!isEmpty(animation) ? (
          <Lottie options={defaultOptions} height={300} width={300} />
        ) : (
          <CircularProgress
            thickness={7}
            color="primary"
            variant="indeterminate"
          />
        )}
      </span>
      {showQuotes && (
        <Typography
          className={classes.quotes}
          variant="h6"
          color="primary"
          style={{ margin: "2rem 0" }}
        >
          {quotes[random(0, quotes.length - 1)]}
        </Typography>
      )}
    </div>
  );
};

SplashScreen.propTypes = {
  classes: PropTypes.object,
  absolute: PropTypes.bool,
  animation: PropTypes.string,
  showQuotes: PropTypes.bool,
};

SplashScreen.defaultProps = {
  absolute: false,
  showQuotes: true,
};

SplashScreen.displayName = "SplashScreen";

export default SplashScreen;
