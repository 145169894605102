export default theme => ({
  root: {
    padding: 20,
    background: "white",
    minHeight: 218,
    display: "flex",
    marginBottom: 30,
    marginTop: -115,
    maxWidth: 1120,
    width: "100%",
    boxSizing: "border-box",
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.1)",
    borderRadius: 6,
    [theme.breakpoints.up("md")]: {
      padding: 43
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: -187
    }
  },
  mainField: {
    marginRight: 25
  },
  infoContainer: {
    width: "100%",
    maxWidth: 768
  },
  submitButton: {
    marginTop: 20,
    [theme.breakpoints.up("sm")]: {
      marginLeft: 20,
      width: 236
    }
  },
  infoText: {
    marginTop: 28
  },
  feedback: {
    width: "100%",
    height: 33,
    borderRadius: 6,
    marginTop: 20,
    [theme.breakpoints.up("md")]: {
      marginTop: 10
    }
  },
  formInfo: {
    backgroundColor: "rgba(103,170,209,0.1)",
    color: "#2A333C"
  },
  error: {
    backgroundColor: "#C72323",
    color: "white"
  },
  fieldsContainer: props => ({
    overflow: "hidden",
    transition: theme.transitions.create("height", {
      duration: ".5s",
      easing: "ease-out"
    })
  })
});
