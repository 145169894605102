import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";

class Countdown extends PureComponent {
  constructor() {
    super();
    this.state = { time: {}, seconds: 599 };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = ("0" + Math.ceil(divisor_for_seconds)).slice(-2);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds
    };
    return obj;
  }

  componentDidMount() {
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.startTimer();
    this.setState({ time: timeLeftVar });
  }

  componentWillUnmount() {
    this.setState({ time: 0 });
    clearInterval(this.timer);
  }

  startTimer() {
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds
    });

    // Check if we're at zero.
    if (seconds === 0) {
      // this.props.handleDisabled(true);
      clearInterval(this.timer);
    }
  }

  render() {
    const { title, classes } = this.props;
    return (
      <Typography gutterBottom variant="body1">
        {title}
        <span className={classes.counter}>
          {this.state.time.m}:{this.state.time.s}
        </span>
      </Typography>
    );
  }
}

Countdown.propTypes = {
  title: PropTypes.string.isRequired,
  handleDisabled: PropTypes.func.isRequired
};

Countdown.displayName = "Countdown";

export default Countdown;
