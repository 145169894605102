import * as SIZE from "utils/constants";

// Woraround: Since classes doesn't apply correctly to react-slick
// use @global prop to override classes and style.

export default theme => ({
  "@global": {
    ".slick-prev::before, .slick-next::before": {
      display: "none"
    },
    ".slick-slide": {
      "& div": {
        outline: "none !important"
      }
    },
    ".slick-arrow": {
      width: 32,
      height: 32
    },
    ".slick-next": {
      right: -35
    },
    ".slick-prev": {
      left: -35
    },
    ".container-photo": {
      width: "288.5px !important",
      "& > img": {
        cursor: "pointer",
        borderRadius: 6,
        width: "262.5px",
        height: "240px",
        objectFit: "cover",

        [theme.breakpoints.down(SIZE.SMALL)]: {
          width: "149px",
          height: "139px"
        }
      },

      [theme.breakpoints.down(SIZE.SMALL)]: {
        width: "163px !important"
      }
    }
  }
});
