import * as SIZE from "utils/constants.js";

export default theme => ({
  splash: {
    display: "flex",
    flex: "1",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  absolute: {
    height: "100vh"
  },
  quotes: {
    textAlign: "center",
    padding: "0 20%",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      display: "block",
      width: "100%"
    }
  }
});
