//Action types
const ADD = "ADD_TO_BOAT";

//Functions

//Action creators
export function add(payload) {
  return {
    type: ADD,
    payload
  };
}

//Reducers
const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD:
      return { ...state, ...action.payload };
    case "RESET":
      return initialState;
    default:
      return state;
  }
};
