import Extra from "./extra.js";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { select, add } from "redux/extra";
import { withStyles } from "@material-ui/core/styles";
import { getFilters } from "redux/search/selectors.js";
import {
  getExtraIsLoading,
  getExtraHolded,
  getExtraError,
  getExtraList,
  getSelectedExtra
} from "redux/extra/selectors.js";
import { searchOffer } from "redux/offer";
import { getMarinaDetails } from "redux/marina/selectors.js";
import { getOfferIsLoading } from "redux/offer/selectors.js";
import styles from "./styles.js";

const mapStateToProps = (state, props) => {
  return {
    filter: getFilters(state),
    isLoading: getExtraIsLoading(state) || getOfferIsLoading(state),
    holded: getExtraHolded(state),
    error: getExtraError(state),
    extraList: getExtraList(state),
    selectedExtra: getSelectedExtra(state),
    marinaDetails: getMarinaDetails(state),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = {
    searchOffer: bindActionCreators(searchOffer, dispatch),
    select: bindActionCreators(select, dispatch),
    add: bindActionCreators(add, dispatch)
  };
  return { actions };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Extra));
