import React from "react";
import { Route } from "react-router-dom";

const ScrollToTop = () => (
  <Route
    component={() => {
      window.scrollTo(0, 0);
      return null;
    }}
  />
);

export default ScrollToTop;
