import * as SIZE from "utils/constants.js";

export default theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  col: {
    margin: "0 6%",
    backgroundColor: "#F5F5F5",
    height: "40vh",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      height: 0
    }
  },
  selector: {
    position: "absolute",
    width: "70%",
    marginLeft: "auto",
    marginRight: "auto",
    left: 0,
    right: 0,
    top: "40%",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      position: "relative",
      width: "92%",
      padding: "4%",
      margin: "0",
      backgroundColor: "#F5F5F5"
    }
  },
  fontTitle: {
    textAlign: "center",
    paddingTop: "60px",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      paddingTop: 0
    }
  },
  fontSubTitle: {
    textAlign: "center",
    paddingTop: "40px"
  },
  buttons: {
    flex: 1,
    padding: "40px 0",
    justifyContent: "center",
    display: "flex",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      flexDirection: "column",
      padding: "10px 0"
    }
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    fontSize: "14px",
    padding: 0,
    margin: "1%",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      margin: "3%"
    }
  },
  link: {
    width: "200px",
    padding: "15px",
    color: "inherit",
    textDecoration: "none",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      width: "100%"
    }
  }
});
