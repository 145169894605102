import { Button as MaterialButton } from "@material-ui/core";
import React from "react";
import classNames from "classnames";

const Button = ({
  classes,
  children,
  className,
  disabled,
  variant,
  ...rest
}) => {
  return (
    <MaterialButton
      fullWidth
      className={classNames(classes.root, classes[variant], {
        [className]: !!className
      })}
      {...rest}
      disabled={disabled}
      variant={variant === "primary" ? "contained" : "outlined"}
    >
      {children}
    </MaterialButton>
  );
};

Button.defaultProps = {
  variant: "primary"
};

export default Button;
