import * as SIZE from "utils/constants.js";

export default theme => ({
  root: {
    padding: "0 20px",
    background: "white"
  },
  border: {
    borderBottom: "3px solid #3788B0"
  },
  appBar: {
    boxShadow: "none",
    maxWidth: 1128,
    margin: "0 auto"
  },
  toolbar: {
    width: "100%",
    height: "100px",
    padding: 0,
    backgroundColor: "white",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      justifyContent: "unset"
    }
  },
  logo: {
    display: "flex",
    flex: "1",
    alignItems: "center",
    maxHeight: "100px"
  },
  fontTitle: {
    fontSize: "28px",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      fontSize: "16px"
    }
  },
  image: {
    maxHeight: "75px",
    padding: "10px 10px 10px 0",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      maxWidth: "42.86px",
      maxHeight: "48px"
    }
  },
  button: {
    color: "gray",
    fontWeight: "100",
    fontSize: "14px",
    padding: 0,
    minWidth: 103.5,
    border: "1px solid #E5E5E5",
    borderRadius: 4
  },
  link: {
    color: "black",
    padding: "10px",
    textTransform: "initial",
    textDecoration: "none"
  }
});
