import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";

const AlertMsg = ({ classes, amount, ...rest }) => {
  return (
    <Typography gutterBottom className={classes.title} variant="body1">
      Only {amount} space{parseInt(amount, 10) <= 1 ? "" : "s"} left!
    </Typography>
  );
};

AlertMsg.propTypes = {
  classes: PropTypes.object.isRequired,
  amount: PropTypes.number.isRequired
};

AlertMsg.displayName = "AlertMsg";

export default AlertMsg;
