export default theme => ({
  submittedWithError: {
    border: "1px solid red",
    padding: "15px",
    borderRadius: "6px",
    margin: "16px 0px",
    textAlign: "center"
  },
  error: {
    color: "red"
  }
});
