import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import { getContactDetails } from "utils/localStorage.js";
import { isMailValid, isOnlyTextValid } from "utils/validations";
import { isValidPhoneNumber } from "react-phone-number-input";
import SplashScreen from "components/Shared/SplashScreen";
import { DEFAULT_COUNTRY, OFFERS } from "utils/constants.js";
import { get, isEmpty } from "lodash";
import { capitalizeFirstLetter, getCountries, getCountryByCode, getProvinces } from "utils/helper.js";

import { TextField, PhoneInput, Autocomplete } from "components/FormFields";

const ContactDetails = ({
  classes,
  actions,
  contactDetail,
  isLoading,
  loaded,
  error
}) => {
  const [countries, setCountries] = useState({});
  const [provinces, setProvinces] = useState({});
  const [phoneRequired, setPhoneRequired] = useState(true);
  const isValidPhone = value =>
    !isEmpty(value) ? isValidPhoneNumber(value) : true;

  useEffect(() => {
    // set form initial state
    setCountries(getCountries());
    const prov = getProvinces(
      get(contactDetail, "country.value", "United States")
    );
    setProvinces(prov);
    // BEGIN - If date from localStorage, retrieve it
    getContactDetails() &&
    (contactDetail = getContactDetails()) && // eslint-disable-line
      actions.add(contactDetail);
    // END - If date from localStorage, retrieve it

    !get(contactDetail, "firstName") &&
      actions.add({ firstName: { value: "", required: true } });
    !get(contactDetail, "lastName") &&
      actions.add({ lastName: { value: "", required: true } });
    !get(contactDetail, "address1") &&
      actions.add({ address1: { value: "", required: true } });
    !get(contactDetail, "email") &&
      actions.add({ email: { value: "", required: true, isValid: false } });
    !get(contactDetail, "country") &&
      actions.add({
        country: { value: "United States", required: true }
      });
    !get(contactDetail, "province") &&
      actions.add({
        province: {
          value: "",
          required: !isEmpty(prov) ? true : false
        }
      });
    !get(contactDetail, "postalCode") &&
      actions.add({ postalCode: { value: "", required: true } });
    !get(contactDetail, "mobilePhone") &&
      actions.add({
        mobilePhone: { value: "", required: phoneRequired, isValid: false }
      });
    !get(contactDetail, "MobilePhoneCountryCode.value") &&
      actions.add({
        MobilePhoneCountryCode: { value: DEFAULT_COUNTRY.callingCode, required: false  }
      });
    !get(contactDetail, "MobilePhonecountryIso3.value") &&
      actions.add({
        MobilePhonecountryIso3: { value: DEFAULT_COUNTRY.alpha3Code, required: false }
      });
    !get(contactDetail, "homePhone") &&
      actions.add({
        homePhone: { value: "", required: phoneRequired, isValid: false }
      });
    !get(contactDetail, "HomePhoneCountryCode.value") &&
      actions.add({
        HomePhoneCountryCode: { value: DEFAULT_COUNTRY.callingCode, required: false }
      });
    !get(contactDetail, "HomePhonecountryIso3.value") &&
      actions.add({
        HomePhonecountryIso3: { value: DEFAULT_COUNTRY.alpha3Code, required: false }
      });
  }, []);

  useEffect(() => {
    get(contactDetail.country, "value", 0) &&
      setProvinces(
        getProvinces(get(contactDetail.country, "value", "United States"))
      );
  }, [contactDetail.country]);

  useEffect(() => {
    setPhoneRequired(
      isEmpty(get(contactDetail.mobilePhone, "value")) &&
        isEmpty(get(contactDetail.homePhone, "value"))
    );
  }, [contactDetail.mobilePhone, contactDetail.homePhone]);

  const handleChange = ({ id, value, isValid, required }) => {
    if (id === "country") {
      let provinces = [];
      if (!isEmpty(value)) {
        provinces = getProvinces(value);
        setProvinces(provinces);
      }
      actions.add({
        province: {
          value: "",
          required: !isEmpty(provinces) ? true : false
        }
      });
    }
    if (id === "mobilePhone") {
      const homePhoneValue = get(contactDetail, "homePhone.value", "");
      actions.add({
        homePhone: {
          value: homePhoneValue,
          isValid: isValidPhone(homePhoneValue),
          required: isEmpty(value) && isEmpty(homePhoneValue)
        }
      });
    }
    if (id === "homePhone") {
      const mobilePhoneValue = get(contactDetail, "mobilePhone.value", "");
      actions.add({
        mobilePhone: {
          value: mobilePhoneValue || "",
          isValid: isValidPhone(mobilePhoneValue),
          required: isEmpty(value) && isEmpty(mobilePhoneValue)
        }
      });
    }
    actions.add({ [id]: { value: value || "", isValid, required } });
  };
  
  const initialHomePhoneCountry = getCountryByCode({ iso3: get(contactDetail, "HomePhonecountryIso3.value")});
  const initialMobilePhoneCountry = getCountryByCode({ iso3: get(contactDetail, "MobilePhonecountryIso3.value")});

  const handlePhoneCountryChange = ({ id, value }) => {
    const phoneId = capitalizeFirstLetter(id);
    const country = getCountryByCode({ iso2: value });

    let alpha3CodeValue = "";
    let countryCodeValue = "";
    if (country) {
      alpha3CodeValue = country.alpha3Code;
      countryCodeValue = country.callingCodes[0];
    }

    actions.add({
      [`${phoneId}countryIso3`]: { value: alpha3CodeValue },
      [`${phoneId}CountryCode`]: { value: countryCodeValue }
    });
  }

  if (isLoading || isEmpty(countries))
    return <SplashScreen animation="lighthouse" />;
  if (loaded && error) {
    return <Redirect to={OFFERS} push />;
  }

  return (
    <form className={classes.container} noValidate autoComplete="off">
      <Paper className={classes.root} elevation={1}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={12} sm={6}>
            <TextField
              id="firstName"
              label="First Name"
              value={get(contactDetail, "firstName.value", "")}
              submitValue={e => handleChange(e)}
              onInput={e => {
                e.target.value = isOnlyTextValid(e.target.value);
              }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="lastName"
              label="Last Name"
              value={get(contactDetail, "lastName.value", "")}
              submitValue={e => handleChange(e)}
              onInput={e => {
                e.target.value = isOnlyTextValid(e.target.value);
              }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="address1"
              label="Address Line 1"
              value={get(contactDetail, "address1.value", "")}
              submitValue={e => handleChange(e)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="address2"
              label="Address Line 2"
              value={get(contactDetail, "address2.value", "")}
              submitValue={e => handleChange(e)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="city"
              label="City / Town "
              value={get(contactDetail, "city.value", "")}
              submitValue={e => handleChange(e)}
              required
            />
          </Grid>
          {!isEmpty(provinces) ? (
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="province"
                label="State / Province / Region "
                value={get(contactDetail, "province.value", "")}
                values={provinces}
                submitValue={e => handleChange(e)}
                required
              />
            </Grid>
          ) : (
            <Hidden only="xs">
              <Grid item sm={6} />
            </Hidden>
          )}
          <Grid item xs={12} sm={6}>
            <TextField
              id="postalCode"
              label="ZIP / Postal Code"
              value={get(contactDetail, "postalCode.value", "")}
              submitValue={e => handleChange(e)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="country"
              label="Country"
              value={get(contactDetail, "country.value", "United States")}
              values={countries}
              submitValue={e => handleChange(e)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="email"
              label="Email"
              value={get(contactDetail, "email.value", "")}
              submitValue={e => handleChange(e)}
              validate={v => isMailValid(v)}
              required
            />
          </Grid>
          <Hidden smUp>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                gutterBottom
                style={{ marginTop: "-10px", marginBottom: "-15px" }}
              >
                At least 1 phone number is required to continue.
              </Typography>
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={6}>
            <PhoneInput
              id="mobilePhone"
              label="Mobile Phone"
              style={{
                border: "1px solid #c4c4c4",
                padding: "12px",
                borderRadius: "3px"
              }}
              placeHolder="Enter Mobile Number"
              value={get(contactDetail, "mobilePhone.value")}
              initialCountry={initialMobilePhoneCountry ? initialMobilePhoneCountry.alpha2Code : ""}
              submitValue={e => handleChange(e)}
              onCountryFlagChange={handlePhoneCountryChange}
              validate={v => isValidPhoneNumber(v)}
              required={
                phoneRequired ||
                !isValidPhone(get(contactDetail, "mobilePhone.value"))
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PhoneInput
              id="homePhone"
              label="Home Phone"
              style={{
                border: "1px solid #c4c4c4",
                padding: "12px",
                borderRadius: "3px"
              }}
              placeHolder="Enter Phone Number"
              value={get(contactDetail, "homePhone.value")}
              initialCountry={initialHomePhoneCountry ? initialHomePhoneCountry.alpha2Code : ""}
              submitValue={e => handleChange(e)}
              onCountryFlagChange={handlePhoneCountryChange}
              validate={v => isValidPhoneNumber(v)}
              required={
                phoneRequired ||
                !isValidPhone(get(contactDetail, "homePhone.value"))
              }
            />
          </Grid>
          <Hidden only="xs">
            <Grid item xs={12}>
              <Typography
                variant="body1"
                gutterBottom
                style={{ marginTop: "-10px" }}
              >
                At least 1 phone number is required to continue.
              </Typography>
            </Grid>
          </Hidden>
        </Grid>
      </Paper>
    </form>
  );
};

ContactDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  contactDetail: PropTypes.object.isRequired
};

ContactDetails.displayName = "ContactDetails";

export default ContactDetails;
