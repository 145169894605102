import * as SIZE from "utils/constants";

export default theme => ({
  root: {
    top: 0,
    left: 0,
    position: "fixed",
    height: 116,
    borderRadius: "0 0 6px 6px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.1)",
    zIndex: 999,
    padding: 10,
    cursor: "pointer",

    [theme.breakpoints.down(SIZE.SMALL)]: {
      height: 126,
      padding: "0 30px"
    }
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "transparent",
    zIndex: 9
  },
  content: {
    maxWidth: 1128,
    margin: "0 auto"
  },
  inputContainer: {
    marginRight: 40,

    "& > label": {
      marginRight: 12
    },

    "& > div": {
      maxWidth: 216
    },

    [theme.breakpoints.down(SIZE.SMALL)]: {
      marginRight: 0,

      "&:not(:last-child)": {
        marginRight: 25
      }
    }
  },
  submitButton: {
    fontSize: 18,
    fontFamily: "Maven Pro",
    textTransform: "none"
  },
  expandText: {
    color: "#67AAD1",
    fontFamily: "Maven Pro",
    fontSize: 16,
    textDecoration: "underline"
  },
  buttonContainer: {
    marginLeft: 20,
    flexBasis: 184
  }
});
