import Blank from "./Blank.js";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles.js";
import { connect } from "react-redux";
import { getOfferType } from "redux/offer/selectors.js";

const mapStateToProps = (state, props) => {
  return {
    offerType: getOfferType(state)
  };
};

export default connect(null, mapStateToProps)(withStyles(styles)(Blank));
