import * as SIZE from "utils/constants.js";

export default theme => ({
  container: { flex: "1" },
  root: {
    padding: "60px",
    height: "87%",
    minHeight: "400px",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      height: "95%",
      maxWidth: "100%",
      padding: "25px"
    }
  }
});
