import BusinessList from "./businessList";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { searchMarinas } from "redux/marina";
import { getAllMarinas, isLoading } from "redux/marina/selectors.js";

import styles from "./styles.js";

const mapStateToProps = (state, props) => {
  return {
    isLoading: isLoading(state),
    marinas: getAllMarinas(state)
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = {
    searchMarinas: bindActionCreators(searchMarinas, dispatch)
  };
  return { actions };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BusinessList));
