import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import { isEmpty } from "lodash";
import styles from "./styles.js";
import { DEFAULT_COUNTRY } from "utils/constants.js";

const phone = ({
	id,
	label,
	placeHolder,
	initialCountry,
	classes,
	className,
	value,
	required,
	validate,
	submitValue,
	onCountryFlagChange,
	...rest
}) => {
	const isValidPhone = (value) =>
		!isEmpty(value) ? isValidPhoneNumber(value) : true;
	const initialCountryCode = !isEmpty(initialCountry) ? initialCountry : DEFAULT_COUNTRY.alpha2Code;

	return (
		<PhoneInput
			style={{
				border: !required ? "1px solid #c4c4c4" : "1px solid #C72223",
				padding: "12px",
				borderRadius: "3px",
			}}
			className={classes.phoneInput}
			placeholder={placeHolder}
			value={value || ""}
			country={initialCountryCode}
			international={true}
			flags={flags}
			onChange={(e) => {
				return submitValue({
					id: id,
					value: e,
					required: required,
					isValid: isValidPhone(e),
				});
			}}
			onCountryChange={countryCode => {
				onCountryFlagChange({
					id,
					value: countryCode || "",
				})
			}}
		/>
	);
};

phone.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string,
	placeHolder: PropTypes.string,
	initialCountry: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	classes: PropTypes.object,
	className: PropTypes.object,
	required: PropTypes.bool,
	submitValue: PropTypes.func.isRequired,
	onCountryFlagChange: PropTypes.func,
	validate: PropTypes.func,
};

export default withStyles(styles)(phone);
