import OfferBox from "./offer.js";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles.js";
import { bindActionCreators } from "redux";
import { addToSelected } from "redux/offer/index.js";
import { getSelectedProperties } from "redux/offer/selectors.js";

const mapStateToProps = (state, props) => {
  return {
    selectedProperties: getSelectedProperties(state)
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = {
    addToSelected: bindActionCreators(addToSelected, dispatch)
  };

  return { actions };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(OfferBox));
