import { useSelector } from "react-redux";
import smartlookClient from "smartlook-client";
import { get } from "lodash";

const useAnalytics = () => {
  const contact = useSelector(state => state.contact);

  const notifyUser = () => {
    if (window["runtime"].smartlookApiKey) {
      const fullName = `${get(contact, "firstName.value", "")} ${get(
        contact,
        "lastName.value",
        ""
      )}`.trim();

      const email = get(contact, "email.value", "");

      smartlookClient.identify(fullName, {
        name: fullName,
        id: email,
        email: email
      });
    }
  };

  return {
    notifyUser
  };
};

export default useAnalytics;
