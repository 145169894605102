import Search from "./search.js";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { resetOfferStore, setOfferTypeAsDockage } from "redux/offer/index.js";
import { resetExtraStore } from "redux/extra/index.js";
import { add } from "redux/search";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { getMarina } from "redux/marina/selectors.js";
import { getFilters } from "redux/search/selectors.js";
import styles from "./styles.js";

const mapStateToProps = (state, props) => {
  return {
    marina: getMarina(state),
    filter: getFilters(state)
  };
};
const mapDispatchToProps = (dispatch, props) => {
  const actions = {
    add: bindActionCreators(add, dispatch),
    resetOfferStore: bindActionCreators(resetOfferStore, dispatch),
    resetExtraStore: bindActionCreators(resetExtraStore, dispatch),
    setOfferTypeAsDockage: bindActionCreators(setOfferTypeAsDockage, dispatch)
  };

  return { actions };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(Search)));
