import * as SIZE from "utils/constants.js";

export default theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      backgroundColor: "#F5F5F5"
    }
  },
  background: {
    margin: "0 7%",
    padding: "5% 9%",
    borderTop: `5px solid ${theme.palette.primary.main}`,
    backgroundColor: "#F3F5F6",
    minWidth: "1000px",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      minWidth: "300px",
      margin: "0 0 100px 0",
      padding: "0 7%"
    }
  },
  page: {
    display: "flex",
    alignItems: "flex-start"
  },
  mainComponent: {
    flex: "1",
    display: "flex",
    minHeight: "40vh",
    paddingRight: "4%",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      padding: 0,
      minHeight: "55vh",
      width: "100%"
    }
  },
  action: {
    position: "sticky",
    top: "2rem",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      position: "fixed",
      bottom: 0,
      left: 0,
      top: "inherit",
      padding: 0,
      width: "100%",
      zIndex: 2
    }
  }
});
