import * as SIZE from "utils/constants";

export default theme => ({
  header: {
    height: 315,
    backgroundPosition: "center",
    backgroundSize: "cover",
    padding: "0 10px",

    [theme.breakpoints.down(SIZE.XSMALL)]: {
      fontSize: 32,
      height: 193,
      textAlign: "center"
    }
  },
  titleContainer: {
    width: "100%",
    maxWidth: 1120,
    marginTop: 40,
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      marginTop: 25
    }
  },
  title: {
    color: "#fff",
    fontSize: 40,
    fontWeight: "bold",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      fontSize: 28
    }
  },
  resourcesGrid: {
    maxWidth: 1155,
    padding: "0 7.5px",

    [theme.breakpoints.down(SIZE.SMALL)]: {
      padding: "0 7.5px"
    }
  },
  gridItem: {
    marginBottom: 35,
    "& > div": {
      margin: "auto"
    },

    [theme.breakpoints.down(SIZE.SMALL)]: {
      marginBottom: 30
    }
  },
  searchContainer: {
    padding: "0 10px"
  },
  transparent: {
    opacity: 0.4
  },
  noAvailability: {
    margin: "10px 0",
    color: "red",
    fontSize: 20,
  },
});
