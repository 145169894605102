import * as SIZE from "utils/constants.js";

export default theme => ({
  paper: {
    maxWidth: "90vw",
    width: "90vw",
  },
  mapContainer: {
    "& svg": {
      width: "100%",
      height: "auto",

      [theme.breakpoints.down(SIZE.XSMALL)]: {
        width: 1000
      }
    }
  },
  button: {
    fontSize: 17,

    [theme.breakpoints.down(SIZE.XSMALL)]: {
      fontSize: 14
    }
  },
  content: {
    padding: 0
  }
});
