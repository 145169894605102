import { TextField, FormControl } from "@material-ui/core";
import React from "react";
import Label from "../Label";

const Input = ({
  classes,
  type,
  label,
  required,
  value,
  onChange,
  id,
  name,
  error,
  inputProps,
  rest
}) => {
  return (
    <FormControl fullWidth>
      {label && (
        <Label error={error} required={required}>
          {label}
        </Label>
      )}
      <TextField
        error={error}
        id={id}
        name={name}
        type={type}
        variant="outlined"
        className={classes.commonInput}
        value={value}
        onChange={onChange}
        required={required}
        InputProps={{ inputProps }}
        {...rest}
      />
    </FormControl>
  );
};

export default Input;
