import * as SIZE from "utils/constants.js";

export default theme => ({
  root: {
    height: 320,
    borderRadius: 6,
    backgroundPosition: "center",
    backgroundSize: "cover",
    maxWidth: 350,

    "&:hover": {
      "& #details": {
        height: "100%",
        fontSize: 32,
        borderRadius: 6,

        "& > div": {
          marginBottom: 24,
          "& > span": {
            "-webkit-line-clamp": 3,
          }
        }
      },
      "& #details-button": {
        opacity: 1,
        transition: theme.transitions.create(["opacity", "transform"], {
          duration: ".5s",
          delay: ".2s",
          easing: "ease-out"
        })
      }
    }
  },
  details: {
    height: 72,
    borderRadius: "0 0 6px 6px",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    flex: 1,
    fontSize: 20,
    fontWeight: "bold",
    color: "#fff",
    overflow: "hidden",
    transition: theme.transitions.create(["height", "font-size"], {
      duration: ".5s"
    })
  },
  nameContent: {
    transition: theme.transitions.create(["margin-bottom"], {
      delay: ".2s",
      easing: "ease-out"
    }),
    height: 72,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  name: {
    color: "#fff",
    textAlign: "center",
    margin: 0,
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
  detailsButton: {
    height: 48,
    width: 188,
    borderRadius: 4,
    backgroundColor: "#3788B0",
    color: "#fff",
    opacity: 0,
    transition: theme.transitions.create(["opacity"], {
      duration: ".2s",
      delay: "0s",
      easing: "ease-in"
    }),

    "&:hover": {
      backgroundColor: "#3788B0"
    },

    [theme.breakpoints.down(SIZE.SMALL)]: {
      opacity: 1,
      height: 40,
      fontSize: 16,
      width: "100%",
      fontWeight: "normal",
      textTransform: "none"
    }
  },
  mobileRoot: {
    minHeight: 218,
    maxWidth: 155
  },
  mobilePhoto: {
    height: 141.71,
    width: "100%",
    borderRadius: 6,
    backgroundPosition: "center",
    backgroundSize: "cover"
  },
  mobileName: {
    color: "#2A333C",
    fontFamily: "Maven Pro",
    fontSize: 16,
    fontWeight: "bold",
    margin: "5.65px 0"
  }
});
