export const quotes = [
  "We can help secure your reservation but can't bring you a rum punch. Sorry.",
  "Docking successfully the first time is my superpower.",
  "Red right returning.",
  "Anyone can hold the helm when the seas are calm.",
  "We're going below deck to work on your booking. 75% chance we hit our head on the way down.",
  "If money can't buy happiness, explain boats!",
  "Give us a moment to trim the engines.",
  "Step 1: Buy a boat. Step 2: Enjoy life.",
  "I'm sorry for what I said when I was docking.",
  "Where do you take a sick boat? To the dock!!",
  "Yes, I do have a retirement plan, I plan on sailing.",
  "Calm down. I'm a sailor, I've seen worse."
];
