import React, { useEffect } from "react";
import PropTypes from "prop-types";
import OfferBox from "components/Shared/OfferBox";
import SplashScreen from "components/Shared/SplashScreen";
import NoResults from "components/Shared/NoResults";
import Error from "components/Shared/Error";
import { Redirect } from "react-router-dom";
import { get, isEmpty } from "lodash";
import * as ROUTES from "utils/constants.js";

const Offer = ({
  classes,
  actions,
  isLoading,
  loaded,
  holded,
  error,
  search,
  filter,
  offerList,
  extraList,
  marinaDetails,
  selectedOffer,
  searchMap
}) => {
  useEffect(() => {
    if (!loaded && isEmpty(offerList)) {
      actions.search(filter, marinaDetails.MarinaId);
      actions.searchMap(marinaDetails.MarinaId);
    }

    return () => actions.add({ holded: false });
  }, [actions, filter, offerList, marinaDetails, loaded]);

  const handleSelect = id => {
    actions.resetExtraStore();
    actions.add({ selected: { id, type: ROUTES.OFFER_TYPES.DOCKAGE } });
    actions.add({ error: "" });
    const extras = offerList.find(i => i.offerID === id).extras;
    actions.addExtra({ extras });
  };
  if (isLoading) return <SplashScreen animation="lighthouse" />;
  if (isEmpty(offerList)) return <NoResults />;
  if (holded && !isEmpty(extraList))
    return <Redirect to={ROUTES.EXTRAS} push />;
  if (holded && isEmpty(extraList))
    return <Redirect to={ROUTES.CONTACT_DETAILS} push />;

  return (
    <div className={classes.container}>
      {error && <Error message={error} />}
      {offerList.map((item, i) => {
        const discountChar = char => char === "Percentage" && "%";
        const offerMSG = (
          discountAmount,
          discountMethod,
          averageNightlyRate
        ) => {
          return discountAmount !== 0
            ? `${discountAmount}${discountChar(discountMethod)} off (was $${
                Math.round(averageNightlyRate) !== averageNightlyRate
                  ? parseFloat(averageNightlyRate, 10).toFixed(2)
                  : averageNightlyRate
              })`
            : "";
        };
        return (
          <OfferBox
            id={item.offerID}
            key={item.offerID}
            selected={get(selectedOffer, "id") === item.offerID}
            filter={filter}
            title={item.customerOfferName}
            description={item.customerDescription}
            price={
              item.discountedAverageNightlyRate
                ? item.discountedAverageNightlyRate
                : item.averageNightlyRate
            }
            rates={item.rates}
            priceMsg={item.priceMethod}
            offMsg={offerMSG(
              item.discountAmount,
              item.discountMethod,
              item.averageNightlyRate
            )}
            spaceLeft={item.spacesRemaining}
            standardUnitPrice={item.standardNightlyRate}
            showCalendar={true}
            warnings={[
              item.minimumDurationWarning,
              item.maximumDurationWarning,
              item.shorePowerWarning
            ]}
            handleSelect={handleSelect}
            listOfSpaces={item.spaces}
            selectedOffer={selectedOffer}
            requireSpaceSelection={item.requireSpaceSelection}
          />
        );
      })}
    </div>
  );
};

Offer.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  holded: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  filter: PropTypes.object.isRequired,
  offerList: PropTypes.array.isRequired,
  extraList: PropTypes.array,
  marinaDetails: PropTypes.object.isRequired,
  selectedOffer: PropTypes.object.isRequired
};

Offer.defaultProps = {
  extraList: []
};

Offer.displayName = "Offer";

export default React.memo(Offer);
