import Breadcrumb from "./breadcrumb.js";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import styles from "./styles.js";
import { connect } from "react-redux";
import { getSelectedOffer } from "redux/offer/selectors.js";

const mapStateToProps = (state) => {
  return {
    selectedOffer: getSelectedOffer(state)
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(withRouter(Breadcrumb))
);
